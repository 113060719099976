import banner from "../../images/home/Default.jpg";
import { Breadcrumbs, Banner, Container, NewsMainSection, NewsSubSection } from "storybook";
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { useDataStore } from "../../hooks/useDataStore";
import { app_api } from "../../api";
import { Box } from "grommet";
import { useEffect } from "react";
// import NewsContent from '../../components/News/NewsContent';

const News = observer(() => {
  const location = useLocation();
  const { newsList, loadNews } = useDataStore();

  useEffect(() => {
    if (!newsList.length) loadNews();
  }, []);

  return (
    <>
      <Banner cover={banner} />
      <Container>
        <Breadcrumbs location={location} />
        <Box gap="medium">
          <Box flex direction="column" gap="24px">
            {/* <NewsMainSection newsList={newsList} app_api={app_api} as={Link} /> */}
            <NewsSubSection newsList={newsList} app_api={app_api} as={Link} />
          </Box>
        </Box>
      </Container>
    </>
  );
});

export default News;
