import styled from "styled-components";

export const StyledPersonModal = styled.div`
  max-width: 1280px;
  width: 100%;
`;

export const StyledPersonModalImage = styled.img`
  border-radius: 15px;
  width: 100%;
  max-width: 385px;
  max-height: 440px;
  object-fit: cover;
  display: inline !important;
  background-color: #efefef;
`;

export const StyledPersonModalImageWrapperDefault = styled.div`
  position: relative;
  line-height: 0;
  margin-right: 60px;
  max-width: 385px;
  max-height: 440px;

  @media (max-width: 1000px) {
    margin-right: 0;
  }

  img {
    max-width: 270px;
    max-height: 305px;
    padding: 30px;
  }
`;

export const StyledPersonModalImageWrapper = styled(StyledPersonModalImageWrapperDefault)`
  width: 100%;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 15px;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 44.79%, rgba(0, 0, 0, 0.64) 84.67%);
  }

  img {
    max-width: none;
    max-height: none;
    padding: 0;
  }
`;

export const StyledPersonModalContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

export const StyledPersonModalName = styled.h3`
  font-family: "Gotham Pro";
  font-weight: 700;
  font-size: 24px;
  line-height: 37px;
  color: #e21a25;
  max-width: 450px;

  @media (max-width: 1000px) {
    max-width: none;
  }
`;

export const StyledPersonModalInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  font-family: "Gotham Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;

  ul {
    margin: 0;
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 10px;

    span {
      font-weight: 700;
    }
  }

  p {
    max-width: 560px;
  }
`;
