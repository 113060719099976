import { dateFormation } from "../mocks/time";
import { getDate } from "./date";

export const filterEventsByMonth = (events, month) => {
  return events.filter((e) => {
    const eventMonth = new Date(e.start_time * 1000).getMonth();
    const eventYear = new Date(e.start_time * 1000).getFullYear();

    return eventMonth === month.monthNum && eventYear === month.year;
  });
};

export const getFilterMonths = (events) => {
  const intArray = events.map((e) => {
    return JSON.stringify({
      monthNum: getDate(e.start_time).numMonth,
      month: getDate(e.start_time).initMonth,
      year: getDate(e.start_time).year,
    });
  });
  const newArr = [...new Set(intArray)].map((el, i) => {
    const parseEl = JSON.parse(el);
    return {
      ...parseEl,
      id: `${parseEl.month} ${i}`,
      date_from: dateFormation(parseEl, `from`),
      date_to: dateFormation(parseEl, `to`),
    };
  });

  return newArr;
};
