import { makeAutoObservable, observable } from "mobx";
import { getPath } from "../utils/getPath";
import { removeItemBasket, returnAuthConfig, returnNewArrayByType } from "../utils/utils";

export class PaymentStore {
  promoCodesData = [];
  promoCodeErrorMessage = { message: "", id: "" };
  errorPaymentMessage = {};

  setPromoCodes = (promoCodes) => {
    this.promoCodesData = returnNewArrayByType(this.promoCodes, promoCodes);
  };

  setPromoCodeError = (promoCodeError) => {
    this.promoCodeErrorMessage = promoCodeError;
  };

  removePromoCode = (item) => {
    this.promoCodesData = removeItemBasket(this.promoCodesData, item);
  };

  setError = (error) => {
    this.errorPaymentMessage = error;
  };

  sendPromoCode = async (obj) => {
    const path = getPath(obj);

    const response = await this.api.get(path);

    if (response.status === 200) {
      this.setPromoCodes({
        ...response.data.promocode,
        ...obj,
        code_count: response.data.available_code_count,
      });
      this.setPromoCodeError({ message: "", id: obj.public_id });
    } else if (response.status >= 400) {
      this.setPromoCodeError({ message: response.data.message, id: obj.public_id });
    }
  };

  payment = async (obj) => {
    let config;

    if (localStorage.userObj) {
      const currentUserObj = JSON.parse(localStorage.userObj);
      config = currentUserObj.temporary ? undefined : returnAuthConfig();
    }

    const response = await this.api.post(`/api/payment`, obj, config);

    if (response.status === 200) {
      if (!config) {
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userObj", JSON.stringify(response.data.user));
        }
        window.location.href = response.data.redirect_url;
      } else {
        window.location.href = response.data.redirect_url;
      }
    } else if (response.status >= 400) {
      this.setError({ message: response.data.message });
      console.log(response.data.message, "error");
    }
  };

  get promoCodes() {
    return this.promoCodesData;
  }

  get promoCodeError() {
    return this.promoCodeErrorMessage;
  }

  get errorPayment() {
    return this.errorPaymentMessage;
  }

  constructor(api) {
    this.api = api;

    makeAutoObservable(this, {
      api: observable.ref,
    });
  }
}
