import styled, { css } from "styled-components";

export const StyledWrapper = styled.li`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 1.875rem 1.25rem 2.5rem;
  border-top: 0.0625rem solid rgba(68, 68, 68, 0.4);
  max-width: none;
  flex-shrink: 1.3;
`;
export const StyledLogoWrap = styled.div`
  margin-bottom: auto;
  width: 6rem;
  margin-right: 1.5rem;
  border-radius: 0.5rem;
`;
export const StyledLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const StyledInfoBlock = styled.div`
  flex-grow: 1;
  padding-left: 1.5rem;
  border-left: 1px solid black;
`;
export const StyledInfoWrap = styled.div`
  display: flex;
  font-size: 0.625rem;
  line-height: 1.2;
`;
export const StyledInfoLocation = styled.div`
  margin-right: auto;
  width: auto;
`;
export const StyledInfoText = styled.p`
  margin-top: 0.75rem;
`;
export const StyledInfoFormat = styled.div`
  margin-right: auto;
  width: auto;
`;
export const StyledInfoCount = styled.div`
  margin-right: 1.5rem;
  text-align: right;
`;
export const StyledInfoCountText = styled(StyledInfoText)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledCountButton = styled.button`
  background-color: transparent;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  border: none;
  cursor: pointer;
`;
export const StyledCostWrap = styled.div`
  padding-left: 1.25rem;
  width: 8.25rem;
  border-left: 1px solid black;
`;
export const StyledCost = styled.p`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.222;
  margin-top: 0.125rem;
`;
export const StyledInfoTitle = styled.h2`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
export const StyledInsideItem = styled.div`
  display: flex;
  gap: 1.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.4rem;
`;
export const StyledInsideLocation = styled(StyledInfoLocation)`
  width: 6rem;
  margin-right: auto;
`;
export const StyledAddBlock = styled.div`
  display: grid;
  gap: 1.875rem;
  grid-template-columns: 56% 40%;
  align-items: center;
  margin-top: 1.625rem;
  padding-right: 2rem;
`;
export const StyledPromoCodeBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const StyledInput = styled.input`
  width: 15.375rem;
  min-height: 2.5rem;
  padding: 0.75rem 2.5rem;
  font-size: 0.75rem;
  line-height: 1.166;
  letter-spacing: 0.2px;
  background-color: white;
  border: 1px solid #626262;
  border-radius: 0.3125rem;

  ${(props) =>
    props.errors?.message &&
    css`
      border-color: #c86363;
    `}
  ${(props) =>
    props.promoCodeApplied &&
    css`
      border-color: blue;
    `}
`;
export const StyledButton = styled.button`
  width: 5.125rem;
  padding: 0;
  min-height: 2.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.166;
  margin-left: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  transition: opacity 0.25s ease;
  outline: none;

  &:hover {
    opacity: 0.6;
  }
`;
export const StyledCertificate = styled.p`
  position: relative;
  color: #c86363;
  font-size: 0.625rem;
  line-height: 1.2;
  max-width: 20rem;
`;

export const StyledDeleteButton = styled.button`
  position: relative;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: 2rem;
  width: 3.625rem;
  height: 3.125rem;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    display: block;

    width: 1rem;
    height: 0.125rem;
    background-color: grey;
    border-radius: 0.125rem;
    top: 50%;
    left: 4.5rem;
    transform: translate(-50%, -50%);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;
export const StyledErrorText = styled.span`
  bottom: -1.5rem;
  color: #c86363;
  font-size: 0.75rem;
  left: 0;
  line-height: 1.2;
  position: absolute;
`;
export const StyledDiscount = styled.span`
  font-size: 0.75rem;
`;

export const StyledPopupWrapper = styled.div`
  padding: 40px;
`;
export const StyledInsideTitle = styled.span`
  font-size: 0.7rem;
  font-weight: 700;
`;
