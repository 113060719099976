import { observer } from "mobx-react";
import { Link, useLocation } from "react-router-dom";
import { SignInForm, Banner, Breadcrumbs, Container, AnswerPopup } from "storybook";
import { useUserStore } from "../../../hooks/useUserStore";
import bannerImg from "../../../images/home/Default.jpg";

export const SignIn = observer(() => {
  const { isConfirmEmailError, setConfirmEmailError, authError, setAuthError, login } =
    useUserStore();

  const handlePopup = () => {
    setConfirmEmailError("");
  };
  const location = useLocation();

  return (
    <>
      <Banner cover={bannerImg} />

      <Container>
        <Breadcrumbs location={location} />
        <SignInForm as={Link} authError={authError} setAuthError={setAuthError} login={login} />
      </Container>

      {isConfirmEmailError.length ? (
        <AnswerPopup
          heading={"Ошибка авторизации"}
          paragraph={isConfirmEmailError}
          closePopup={handlePopup}
        />
      ) : null}
    </>
  );
});
