import { Box, Heading, ResponsiveContext, Spinner } from "grommet";
import { useContext } from "react";
import { Container } from "storybook";
import { isMobileTabletResponsive } from "../../../utils/utils";

const MaintenanceErrorPage = () => {
  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);

  return (
    <Box height={isMobileWidth ? "70vh" : "100vh"}>
      <Container>
        <Box
          flex
          direction={isMobileWidth ? "column" : "row"}
          align={isMobileWidth ? "center" : ""}
          justify="evenly"
          gap={isMobileWidth ? "5rem" : ""}
          pad={{
            vertical: isMobileWidth ? "10rem" : "20rem",
            horizontal: isMobileWidth ? "2rem" : "10rem",
          }}
        >
          <Box width={isMobileWidth ? "100%" : "65%"}>
            <Heading level={1}>На сайте ведутся технические работы</Heading>
          </Box>
          <Spinner
            // round={true}
            border={false}
            size="xlarge"
            background="linear-gradient(to right, #F8F8F8, #D0DAE8)"
            animation={[
              { type: "fadeIn", duration: 1500, size: "large" },
              { type: "pulse", duration: 1000, size: "large" },
            ]}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default MaintenanceErrorPage;
