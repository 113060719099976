export const services = {
  dataStore: Symbol.for("dataStore"),
  subscribeStore: Symbol.for("subscribeStore"),
  antiDopingStore: Symbol.for("antiDopingStore"),
  profileStore: Symbol.for("profileStore"),
  userStore: Symbol.for("userStore"),
  api: Symbol.for("api"),
  ticketsStore: Symbol.for("ticketsStore"),
  basketStore: Symbol.for("basketStore"),
  paymentStore: Symbol.for("paymentStore"),
  memberRegistrationStore: Symbol.for("memberRegistrationStore"),
  additionalApi: Symbol.for("additionalApi"),
  membersStore: Symbol.for("membersStore"),
};

export const iocNames = services;
