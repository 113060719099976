import { Box, Heading, Image, Paragraph } from "grommet";
import styled from "styled-components";

import Article from "../index";

import articleBanner from "../../../images/article/triathlon_history_banner.jpg";
import articleImg1 from "../../../images/article/triathlon-in-USSR.jpg";
import articleImg6 from "../../../images/article/alexander-craig.jpg";
import articleImg3 from "../../../images/article/igor-vekshin.jpg";
import articleImg4 from "../../../images/article/ironman.jpeg";
import articleImg5 from "../../../images/article/ironman2.jpg";
import articleImg2 from "../../../images/article/in-USSR.jpg";

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 100%;
  font-size: 16px;
`;

const TriathlonHistory = () => {
  return (
    <Box flex>
      <Article
        title={`История триатлона`}
        desc={`От первых соревнований до сегодняшнего дня`}
        coverBack={articleBanner}
      >
        <CustomParagraph>
          Первые соревнования по триатлону были проведены 25 сентября 1974 года в США в штате
          Калифорния. Организатором стал легкоатлетический клуб из Сан-Диего. Последовательность
          видов, в которых соревновались атлеты, и длина дистанций были следующими: бег 5,3 мили
          (8,5 км), велогонка 5 миль (8 км) и плавание 600 ярдов (549 м).
        </CustomParagraph>
        <CustomParagraph>
          После продолжительных дискуссий, медико-биологических исследований и практических
          экспериментов удалось выработать единые положения, которые и легли в основу{" "}
          <strong>триатлона</strong>: атлеты последовательно преодолевают дистанцию плавания,
          велогонки и бега с переодеванием в одной или в двух транзитных зонах.
        </CustomParagraph>
        <CustomParagraph>
          После того, как в сентябре 1995 года решением МОК триатлон был впервые включен в программу
          Олимпийских игр, международный Союз триатлона (ITU) принял следующее решение:{" "}
          <strong>триатлон</strong> — это плавание 1,5 км, велогонка 40 км, бег 10 км. Именно на
          этой дистанции сильнейшие 50 мужчин и 50 женщин оспаривали первенство на Олимпиаде-2000 в
          Сиднее (Австралия). Дистанция менее дистанции триатлона, как и прежде, называется
          спринтерской (0,75 км + 20 км + 10 км), а более — сверхдлинной (2-4 км + 50-180 км + 15-42
          км).
        </CustomParagraph>

        <Heading level={`3`} margin={{ top: `2rem`, bottom: `18px` }}>
          Триатлон в СССР и России
        </Heading>

        <CustomParagraph>
          В Советском Союзе первый экспериментальный старт на длинной дистанции состоялся в Эстонии,
          в поселке Вока в июле 1984 года: 2000 метров плавания, 200 км вело и марафон. Время
          победителя составило 11:28:47. А уже через год, 28 июля 1985 г., в Перми прошел первый в
          Советском Союзе триатлон на классической дистанции, который организовал клуб любителей
          бега «Вита». Победителем пермского старта и первым «железным человеком» Советского Союза
          стал легендарный триатлонист Л. А. Завьялов (13:06:40), которому принадлежит преодоление
          супертриатлона от Перми до Прибалтики.
        </CustomParagraph>

        <Box
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            marginTop: "1rem",
          }}
        >
          <Image src={articleImg1} />
        </Box>

        <CustomParagraph>
          25-26 июня 1988 года был проведне дебютный чемпионат города Калининграда, который стал
          первым чемпионатом в центральной части нашей страны (до 8 июля 1996 года город Королёв
          (Московская область) назывался Калининградом). Спортсмены соревновались на дистанциях: 500
          м – 23 км – 5 км, 1000 м – 45 км – 11 км, 1900 м – 90 км – 21 км. Победителем «половинки»
          стал Игорь Стрельцов. Второе место занял Владимир Волков (ставший позднее руководителем
          клуба триатлона, членом президиума Федерации триатлона СССР). Бронзу завоевал Павел
          Николаев.
        </CustomParagraph>
        <CustomParagraph>
          И надо остановиться на еще одной строчке в протоколе. На пятой позиции (из 10 участников)
          с результатом 6:41:45 финишировал Николай Бударин (летчик-космонавт РФ, герой Российской
          Федерации, 82-й космонавт России). Позднее Николай был организатором последующих
          триатлонов, начальником трассы международного пробега памяти С.П.Королёва, а затем стал
          первым пролетавшим на двух космических кораблях: американском шаттле и нашей станции
          «Мир». И в свой первый исторический полет взял заверенный судьями экземпляр протокола
          своего первого старта в триатлоне – спорте, воспитывающем мужество, волю и упорство!
        </CustomParagraph>
        <CustomParagraph>
          После чемпионата города, на базе КЛБ «Муравей» был создан один из первых в СССР клубов
          триатлона. В 1989 году Владимир Волков и Игорь Стрельцов стали одними из организаторов
          Федерации триатлона СССР, авторами Устава Федерации и первых разрядных нормативов.
        </CustomParagraph>
        <CustomParagraph>
          В Советском Союзе соревнования на классической дистанции проводились в Перми (28.07.1985,
          27.06.1986, 26.07.1987, 31.07.1988), Уфе (3.07.1988), Эстонии – поселок Вока (12.07.1987,
          17.07.1988), Одессе (31.07.1988), а также в Минске и Донецке.
        </CustomParagraph>

        <Box
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            marginTop: "1rem",
          }}
        >
          <Image src={articleImg2} />
        </Box>

        <CustomParagraph>
          Доподлинно известно о проведении в России четырех стартов на длинной дистанции: двух
          «половинок» и двух гонок на классической дистанции.
        </CustomParagraph>
        <CustomParagraph>
          Первый официальный чемпионат России по триатлону на длинной дистанции состоялся 21 августа
          1994 года в городе Ярославле. За медали боролись 30 спортсменов: 25 мужчин и 5 женщин,
          которые представляли 10 городов России. Победителями дебютного чемпионата стали
          нижегородец Андрей Кремлёвский (3:59:21) и омичка Наталья Сушкова (4:51:20). Кроме того, в
          чемпионате приняли участие такие известные российские триатлеты конца 90-х – начала 2000-х
          годов, как Сергей Пятигорский, Дмитрий Грибков, Юрий Клюхин, Андриян Христофоров. 14 место
          в итоговом протоколе занял будущий ультратриатлет Александр Симонов (4:50:07).
        </CustomParagraph>
        <CustomParagraph>
          Следующий чемпионат прошел 24 августа 1997 года в Тольятти. Он стал более представительным
          и по количеству участников и по количеству городов. Разыграть медали в Тольятти приехал 51
          спортсмен (в том числе 7 женщин) из 14 городов России. Дистанция составляла 2500 м
          плавание – 83,1 км вело – 22 км бег. Лучший результат у мужчин показал Станислав
          Белошапкин из Санкт-Петербурга (4:34:05). У женщин двукратной чемпионкой страны стала
          Наталья Сушкова (4:55:30).
        </CustomParagraph>
        <CustomParagraph>
          В России были организованы два триатлона на классической дистанции. Оба прошли в Королёве
          (Московская область) в 1998 и 1999 годах.
        </CustomParagraph>
        <CustomParagraph>
          4 июля 1998 года на Самаровском пруду Королёва в рамках 10-го чемпионата города по
          триатлону состоялся старт на железной дистанции. Но из 16 заявившихся зарегистрировались
          лишь 6 человек, а на старт вышло пятеро. Дело в том, что был проливной дождь, температура
          воздуха градусов 13 и вода соответствующая. Двое королёвцев замерзли в воде: отец и сын
          Волковы. Первый проплыл 2 километра, второй – 750 метров. Свело ноги судорогой. Попытки
          вылезти из воды и отогреться под холодным дождем тоже ничего не дали. Юрий Михайлин через
          3 километра плавания вылез из воды, расстегнул комбинезон, погрелся и вновь вернулся в
          воду на последний километр. Но после велогонки трое оставшихся на трассе мужественных
          людей заявили, что во время велодистанции было еще холоднее. На бег одевали все, что есть.
          Неволин так и преодолел марафон, накрывшись полиэтиленовой накидкой. А организаторы
          боялись, что намокнут призы. Казалось, что даже в палатке от дождя не скроешься. Досталось
          судьям на дальней точке, которые не рассчитывали на такую холодную, ветряную и дождливую
          погоду. Но с трассы не уйдешь. Ведь триатлонисты не сошли!
        </CustomParagraph>
        <CustomParagraph>
          А лучшее время в итоге показал Александр Симонов (11:19:47). Почти на два часа он опередил
          триатлета из Балашихи Юрия Михайлина (13:38:35). Третьим финишировал Владимир Неволин из
          Железногорска (14:22:04).
        </CustomParagraph>
        <CustomParagraph>
          Последний на сегодняшний день чемпионат России на длинной дистанции состоялся 3 июля 1999
          года. На старт заявились 16 человек из Москвы, Санкт-Петербурга, Балашихи, Железногорска,
          Нижнего Новгорода, Подольска, Ростова-на-Дону, Таганрога, Шахт, Щёлково и Шатуры.
          Благополучно до финиша добрались 10 человек. А чемпионом России с результатом 10:32:33
          стал Алексей Серебрянский из Санкт-Петербурга. Несмотря на то, что с момента проведения
          11-го Королёвского триатлона прошло уже 13 лет, до сих пор помню этот старт во всех
          подробностях, так как сам имел удовольствие принять в нём участие. В силу возраста,
          конечно, не на «железной» дистанции, а на спринтерской. Но на тех, кто отважился покорить
          классический триатлон я смотрел с большим уважением.
        </CustomParagraph>
        <CustomParagraph>
          По состоянию на 4 июля 1999 года в нашей стране насчитывалось 110 россиян, преодолевших
          классический триатлон на соревнованиях в СССР и России. Первое место в этом списке
          занимает Игорь Векшин (Московская область), который 17 июля 1988 года в посёлке Вока
          (Эстония) финишировал спустя 9 часов 40 минут 56 секунд.
        </CustomParagraph>

        <Box
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            marginTop: "1rem",
          }}
        >
          <Image src={articleImg3} />
        </Box>

        <Heading level={`3`} margin={{ top: `2rem`, bottom: `18px` }}>
          IRONMAN
        </Heading>

        <CustomParagraph>
          Идея появления классического триатлона возникла в 1977 году во время церемонии награждения
          участников легкоатлетической эстафеты Oahu PerimeterRelay. Среди участников которой были
          многочисленные представители клуба бегунов Mid Pacific и клуба плавания Waikiki. Члены
          двух клубов долго спорили, какие атлеты более выносливые: пловцы или бегуны. По этому
          случаю офицер ВМС США Джон Коллинз рассказал про статью в журнале Sports Illustrated. Где
          говорилось, что Эдди Меркс, легендарный бельгийский велосипедист, имел самый высокий
          зарегистрированный показатель «максимального потребления кислорода» и таким образом
          велосипедисты являются самыми выносливыми атлетами.
        </CustomParagraph>

        <Box
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            marginTop: "1rem",
          }}
        >
          <Image src={articleImg4} />
        </Box>

        <CustomParagraph>
          Сам Джон Коллинз и его жена Джуди были уже не новичками в триатлоне. Они принимали участие
          в соревнованиях, организованных легкоатлетическим клубом из Сан-Диего в 1974-1975 годах.
          Ряд других военных спортсменов также стартовали в триатлоне в Сан-Диего, поэтому они
          поддержали концепцию Коллинза, когда тот предложил уладить спор путем объединения трех уже
          существовавших на острове соревнований на длинной дистанции в одну гонку: плавания на
          открытой воде Waikiki Roughwater Swim (3,86 км), велогонки Around-Oahu Bike Race (185 км),
          которая проводилась в два дня и марафона Honolulu (42 км 195 м). Коллинз подсчитал, что
          сократив дистанцию велогонки на 3 мили (4,8 км) и проведя ее по часовой стрелке вокруг
          острова, трасса может начаться на финише плавательной дистанции и закончится около башни
          Aloha, традиционного места старта марафона Honolulu.
        </CustomParagraph>
        <CustomParagraph>
          Перед гонкой каждый участник получил по три листа бумаги, на которых были написаны правила
          соревнований и описание трассы. На последней странице от руки был дописан призыв:
          «Проплыви 2,4 мили! Велогонка 112 миль! Пробеги 26,2 мили! Гордись этим всю оставшуюся
          жизнь!» Теперь эта фраза является зарегистрированной торговой маркой.
        </CustomParagraph>
        <CustomParagraph>
          В честь одного из местных бегунов, который был известен своими изматывающими тренировками,
          Коллинз сказал: «Кто бы не финишировал первым, мы назовём его Ironman!» (Железный
          человек). Рано утром 18 февраля 1978 года на старт вышли 15 человек, из которых 12
          благополучно завершили гонку. Первым в истории обладателем титула Ironman стал Гордон
          Халлер, который финишировал с результатом 11 часов 46 минут 58 секунд. В 1979 году без
          особой рекламной кампании гонка собрала уже около 50 участников. Однако старт был отложен
          из-за плохих погодных условий. В результате, только 15 спортсменов вышли на старт в
          воскресенье утром. Победил житель Сан-Диего Том Воррен. Он преодолел всю дистанцию за 11
          часов 15 минут 56 секунд. Велосипедистка из Бостона Лин Лемаир финишировала шестой в общем
          зачёте и стала первой женщиной, покорившей классическую дистанцию.
        </CustomParagraph>
        <CustomParagraph>
          Для того, чтобы привлечь больше участников, Коллинз планировал изменить формат гонки с
          личной на эстафету. Однако этому «помешал» журналист Sports Illustrated Барри Мак Дермотт,
          который написал о ней статью и открыл гонку для широкого круга людей. В течение следующего
          года с Коллинзом связались сотни потенциальных участников.
        </CustomParagraph>
        <CustomParagraph>
          В 1981 году соревнования были перенесены на Гавайи (так называемый Большой остров) и в
          1982 году было изменено время проведения гонки с февраля на октябрь. В результате, в 1982
          году было проведено два старта Ironman. Февральская гонка 1982 года стала вехой в истории
          развития классической дистанции. Студентка колледжа Джули Мосс приближалась к финишу
          триатлона на первом месте в своей возрастной группе. За несколько метров до финиша, в
          результате сильной усталости и обезвоживания организма, она упала и последние метры
          преодолела ползком. Это выступление транслировалось на весь мир, в результате для
          Ironman'а была создана аура, при которой финиш на этой дистанции уже является победой!
        </CustomParagraph>
        <CustomParagraph>
          С течением времени популярность Гавайского триатлона стремительно росла и ежегодные гонки
          на Большом острове стали чемпионатом мира Ironman, для попадания на который нужно
          выступать на отборочных стартах. В 2013 году пройдут 28 стартов по всему миру, которые
          будут являться квалификационными на чемпионат мира 2013 года. Профессиональные спортсмены
          отбираются на Гавайи через систему квалификационных очков, которые они получают, выступая
          на соревнованиях серии Ironman и Ironman 70.3. 50 мужчин и 35 женщин, набравших наибольшее
          количество очков, попадают на чемпионат.
        </CustomParagraph>
        <CustomParagraph>
          Спортсмены-любители могут попасть на Гавайский триатлон заняв определенные места в своих
          возрастных категориях также на серии Ironman и Ironman 70.3. Кроме того, право на участие
          в гонке может быть получено путём лотереи или на благотворительном аукционе.
        </CustomParagraph>
        <CustomParagraph>
          Победа на чемпионате мира Ironman очень почётное и престижное достижение в мире триатлона.
          Но даже участие на нем —для многих атлетов это вершина карьеры.
        </CustomParagraph>
        <CustomParagraph>
          В настоящее время Ironman является зарегистрированным товарным знаком и принадлежит
          Всемирной корпорации триатлона (WTC). Под ее эгидой в мире проводятся десятки стартов на
          классической дистанции (Ironman) и на дистанции вдвое короче (Ironman 70.3). Например, в
          2013 году WTC организует 71 гонку (24 – Ironman и 47 – Ironman 70.3). Большинство
          соревнований Ironman начинаются в 7 утра и имеют строгое ограничение по времени – 17
          часов. Лимит плавания – 2 часа 20 минут, велогонка должна быть завершена к 17:30 и
          полностью вся дистанция к полуночи.
        </CustomParagraph>

        <Box
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            marginTop: "1rem",
          }}
        >
          <Image src={articleImg5} />
        </Box>

        <CustomParagraph>
          Серия Ironman 70.3 была создана WTC в 2005 году. Это более короткая гонка, известная как
          Half Ironman. Она состоит из 1,9 км плавания, 90 км велогонки и 21,1 км бега. Как и
          Ironman, серия 70.3 содержит ряд отборочных стартов по всему миру, которые позволяют
          атлетам квалифицироваться на чемпионат мира 70.3. Ежегодно он проходит на озере Лас-Вегас,
          г. Хендерсон, штат Невада. Для спортсменов-любителей некоторые старты 70.3 позволяют
          квалифицироваться на Гавайский триатлон.
        </CustomParagraph>
        <CustomParagraph>
          Последний на сегодняшний день чемпионат мира Ironman состоялся 13 октября 2012 года. Его
          победителями в абсолютном зачёте стали: австралиец Пит Якобс (8:18:37) и американка Линда
          Кейв (9:15:54). В настоящее время рекорд трассы принадлежит представителю Австралии
          Александру Крейгу, который в 2011 году пересёк финишную черту через 8 часов 3 минуты 56
          секунд. Британка Крисси Веллингтон установила женский рекорд трассы в 2009 году (8:54:02).
        </CustomParagraph>
        <CustomParagraph>
          Наиболее успешно на Гавайском триатлоне выступали: Александр Крейг (3-х кратный чемпион
          мира – 2008, 2009, 2011; обладатель рекорда трассы), Марк Аллен (6-кратный победитель
          Гавайского триатлона; 5 побед подряд – 1989-1993 (абсолютный рекорд)); у женщин: Крисси
          Веллингтон (4-х кратная чемпионка мира; обладательница рекорда трассы у женщин), Наташа
          Бадманн (6-кратная чемпионка мира, первая европейская женщина – победительница Гавайского
          триатлона), Паула Ньюби-Фрейзер (8-кратная чемпионка мира – абсолютный рекорд, 4 победы
          подряд (1991-1994)).
        </CustomParagraph>

        <Box
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            marginTop: "1rem",
          }}
        >
          <Image src={articleImg6} />
        </Box>

        <CustomParagraph>
          Кроме серии Ironman, в мире проходят много других гонок на классической дистанции и на
          «половинке». Наиболее известная из них – серия стартов Challenge (в 2013 году состоятся 12
          стартов этой серии, которые проводятся преимущественно в Европе). Самая популярная гонка
          серии проходит в немецком городе Рут. Она собирает до 5000 участников и по их количеству
          является самой большой в мире на классической дистанции. Второе по значимости соревнование
          проходит также в Германии, в Крайгау. Количество стартующих атлетов около 4000 человек.
          Недаром, в 2012 году именно в Руте и Крайгау прошли официальные чемпионаты Европы ITU по
          триатлону на длинной дистанции. В первом случае на классической дистанции, во втором – на
          «половинке». Также, кроме серии Challenge, в мире проводятся серии стартов по триатлону
          такие как HITS, Revolution 3 и другие.
        </CustomParagraph>
        <CustomParagraph>
          Триатлон на длинной дистанции – именно такое название носит данная дисциплина по правилам
          ITU. Дистанция считается длинной, если она содержит следующие отрезки: плавание от 1000 до
          4000 метров, велогонка от 80 до 180 км., бег от 10 до 42,2 км.
        </CustomParagraph>
        <CustomParagraph>
          Первый официальный чемпионат мира по триатлону на длинной дистанции состоялся 1 октября
          1995 года в Ницце (Франция). В нём приняли участие 20 спортсменов: 10 мужчин и 10 женщин.
          Первыми чемпионами мира стали британец Саймон Лессинг (5:46:17) и представительница Новой
          Зеландии Дженни Роуз (6:28:43).
        </CustomParagraph>
        <CustomParagraph>
          Первый чемпионат Европы на длинной дистанции прошёл 3 августа 2003 года в датском городе
          Фредеричия. На старт вышли 26 мужчин и 14 женщин. Звание чемпионов Европы завоевали
          датчанин Торбьорн Синдбалле (5:43:47) и Эдит Нидерфринигер (6:40:24).
        </CustomParagraph>
        <CustomParagraph>
          И хотя классический триатлон требует от спортсменов многочасовой работы на дистанции,
          огромной выносливости и силы духа, тем не менее, не все «железные люди» были удовлетворены
          столь «короткой» дистанцией. После покорения «Айронмена» фантазии, мечты и цели
          спортсменов были направлены на то, чтобы найти новые горизонты для поиска границ своих
          моральных, физических и психических возможностей.
        </CustomParagraph>
        <CustomParagraph>
          И такой шанс атлетам представился с появлением ультратриатлона, основой для которого стала
          именно классическая дистанция.
        </CustomParagraph>

        <Heading level={`3`} margin={{ top: `2rem`, bottom: `18px` }}>
          УЛЬТРАТРИАТЛОН
        </Heading>

        <CustomParagraph>
          Ультратриатлон — классическая дистанция, увеличенная в 2, 3, 4, 5, 10, 20 раз.
        </CustomParagraph>
        <CustomParagraph>
          Первые соревнования по двойному ультратриатлону состоялись в 1984 году в Хантсвилле, штат
          Алабама (США). Они состояли из 7,6 км плавания, 360 км велогонки и 84,4 км бега,
          дистанции, до сих пор считающейся стандартной для двойного ультратриатлона. В первой
          гонке, которая прошла в День Труда, приняли участие спортсмены со всего мира. Организовали
          данный старт Рэй и Нэнси Шепард. С тех пор гонка выросла, изменилась, стала более
          изнурительной и трудной для преодоления. Теперь она проходит в Вирджинии. Каждый год в ней
          участвуют от 20 до 30 спортсменов. Мировыми рекордсменами на двойной дистанции являются
          швейцарец Адриан Бренвальд (19:50:12, 2011 год, Нойленбах, Австрия) и американка Тина
          Бишофф (22:07:00, 1994 год, Хантсвилл, США).
        </CustomParagraph>
        <CustomParagraph>
          Первый в истории старт по тройному ультратриатлону прошел во французском городе Ле
          Фонтанил в 1988 году. Тогда на старт вышли 11 мужчин, из которых 7 успешно финишировали. В
          1989 году на этой же дистанции стартовала и благополучно закончила гонку первая женщина.
          Всего же с 1988 по 2011 год в мире было проведено 53 старта по тройному ультратриатлону, в
          которых приняли участие 1256 спортсменов (1154 – мужчины, 102 - женщины) из которых до
          финиша добрались 1004 человека (824 – мужчины, 80 – женщин).
        </CustomParagraph>
        <CustomParagraph>
          Обладатели мирового рекорда в тройном ультратриатлоне: у мужчин – австриец Луис
          Вайлдпаннер (31:47:57, 2003 год, Ленсан, Германия); у женщин – немка Астрид Бенор
          (37:54:54, 1996 год, Ленсан, Германия).
        </CustomParagraph>
        <CustomParagraph>
          Рассказывая об ультратриатлоне, нельзя обойти вниманием две дисциплины, которые по праву
          являются самым тяжелым испытанием для человека на планете. Это Дека ультратриатлон и
          двойной Дека ультратриатлон. Проходят эти уникальные состязания в Мексике (г. Монтеррей) в
          октябре-ноябре. Формат гонки постоянно меняется. Например, в 2008 году гонка была
          непрерывная, то есть спортсмены после 36 км плавания преодолевали 1800 км на велосипеде и
          заканчивали кроссом на 422 км. В 2009 году Дека ультратриатлон проходил в формате
          10-дневной гонки, где спортсмены в течение суток преодолевали по одной классической
          дистанции. В 2010 и 2012 годах формат гонки был также непрерывный. Мировым рекордсменом
          Дека ультратриатлона у мужчин является француз Фабрис Лукас, который в 1997 году
          финишировал спустя 192 часа 8 минут 26 секунд. У женщин рекорд принадлежит мексиканке
          Сильвии Алдони. Он был установлен в 1992 году и составляет 249 часов 14 минут 52 секунды.
        </CustomParagraph>
        <CustomParagraph>
          Обладателем мирового рекорда на двойной Дека ультрадистанции является литовский триатлет
          Видмантас Урбонас, который преодолел расстояние в 4520 километров за 437 часов 21 минуту
          40 секунд!!! На 76 километров плавания у него ушел 1 день 7 часов 19 минут 35 секунд, 3600
          км велогонки Видмантас преодолел за 8 дней 23 часа 46 минут 1 секунду. 844 км кросса
          заняли 7 дней 21 час 6 минут 9 секунд.
        </CustomParagraph>
        <CustomParagraph>
          Развитием ультратриатлона в мире занимается Международная ассоциация ультратриатлона
          (IUTA). В 2012 году под эгидой IUTA состоялись 8 гонок на ультрадистанции: 6 этапов Кубка
          мира на двойной дистанции и по одному на тройной и на Дека дистанции. Старт Дека
          ультратриатлона проходил в Мексике (г.Монтеррей) с 22 октября по 2 ноября. В этом году
          соревнования на Дека-дистанции состоялись в 11 раз. Всего два человека вышли на старт. С
          результатом 278 часов 51 минута 57 секунд первым пересёк линию финиша британец Саймон
          Бурнье. Американец Кайл Поланд уступил ему чуть больше 17 часов (296 часов 20 минут 38
          секунд). В эти же сроки в Мексике состоялась гонка на двойной Дека-дистанции (76 км
          плавание – 3600 км велогонка – 844 км бег). На старт этого сумасшедшего состязания
          отважились выйти всего двое смельчаков, среди которых одна женщина. На момент подготовки
          материала шёл 386-й час гонки. Швед Кари Мартенс завершил плавательную и велосипедную
          часть дистанции. И пробежал 104 из 844 км. А вот британка Сурайя Оливер всё ещё находилась
          на велогонке, преодолев 2340 из 3600 километров.
        </CustomParagraph>
        <CustomParagraph>
          В 2013 IUTA запланировала проведение 11 стартов. Семь этапов Кубка мира на двойной
          дистанции. Двух на тройной и двух на Дека дистанции, один из которых по традиции пройдёт в
          Мексике, а второй состоится в сентябре 2013 года в Италии (г. Кастельнуово дель Гарда).
          Итальянская гонка пройдёт в формате 1х10, то есть одна классическая дистанция в сутки в
          течение 10 дней. Помимо Дека старта, итальянские организаторы приготовили для атлетов
          невероятное испытание – самую длинную гонку на выносливость в истории спорта! Она пройдёт
          в формате 1х30. В течение месяца атлеты преодолеют 30 классических дистанций, а общее
          пройденное расстояние будет равно 6781,8 километра. Из которых плавание составит 115,8 км,
          велогонка – 5400 км, бег – 1266 км. Соревнования пройдут под девизом: «Есть ли предел?».
          Для участия в старте уже заявились 26 ультратриатлетов – лучшие в мире специалисты в
          соревнованиях на выносливость. Кроме того, гонка войдёт в книгу рекордов Гиннеса, как
          наиболее продолжительное соревнование по триатлону.
        </CustomParagraph>
        <CustomParagraph>
          Организатор старта – итальянский ультратриатлет Винченцо Каталано. На счету которого
          покорение: 55 классических триатлонов, 15 двойных ультратриатлонов, 7 тройных
          ультратриатлонов, 3 Дека ультратриатлона, 4 – 1х10 ультратриатлона, 1 – пятикратный
          ультратриатлон. А также ему принадлежит два мировых рекорда: в 2004 году он преодолел 31
          классическую дистанцию за год; в 2006 – 36 классических дистанций за год.
        </CustomParagraph>
        <CustomParagraph>
          Рассказывая про ультратриатлон, нельзя не упомянуть Александра Симонова, который стал
          чемпионов Европы в тройном ультратриатлоне (11 км – 540 км – 126 км). Этот титул он
          завоевал в 2000 году в городе Нойленбах (Австрия). Около 10 раз Александр участвовал в
          соревнованиях на классической и на ультрадистанции в России, Литве, Австрии, Германии,
          Франции, Мексике. Александр Симонов – единственный россиянин – участник соревнований по
          ультратриатлону.
        </CustomParagraph>
      </Article>
    </Box>
  );
};

export default TriathlonHistory;
