import styled from "styled-components";

export const StyledForm = styled.form`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;
export const StyledErrorTitle = styled.h2`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
