import { Box, Paragraph, Text } from "grommet";
import Image from "../../images/banners/federation-banner.jpg";
import styled from "styled-components";
import { goals, tasks } from "../../mocks/federation_tasks_goals";
import { Breadcrumbs, Banner, Container } from "storybook";
import { useLocation } from "react-router-dom";

const CustomParagraph = styled(Paragraph)`
  margin-top: 20px;
  margin-bottom: 12px;
  max-width: 100%;
  font-size: 16px;
`;

const P = styled(Paragraph)`
  max-width: 100%;
  font-size: 16px;
  line-height: 30px;
`;

const Federation = () => {
  const location = useLocation();

  return (
    <>
      <Banner cover={Image} />
      <Container>
        <Breadcrumbs location={location} />

        <CustomParagraph>
          <Text style={{ fontWeight: "bolder" }}>Цель:</Text>
          {goals.map((goal) => (
            <P key={goal.id}>{goal.content}</P>
          ))}
        </CustomParagraph>

        <CustomParagraph>
          <Text style={{ fontWeight: "bolder" }}>Задачи:</Text>

          <Box gap="xsmall">
            {tasks.map((task) => (
              <P key={task.id}>• {task.content}</P>
            ))}
          </Box>
        </CustomParagraph>
      </Container>
    </>
  );
};

export default Federation;
