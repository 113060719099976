import { Select } from "grommet";
import styled from "styled-components";

export const StyledMain = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 7.5rem;
`;
export const StyledFilters = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
`;
export const StyledSelect = styled(Select)`
  padding: 15px;
`;
export const StyledAside = styled.aside`
  width: 17.5rem;
  flex-shrink: 0;
  margin-right: 1.5rem;
`;
export const StyledMainContent = styled.div`
  width: 100%;
  position: relative;
  align-self: stretch;
`;
export const StyledList = styled.ul`
  list-style-type: none;
  margin-bottom: 3.5rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
`;
export const StyledItem = styled.li`
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.2s ease;

  &:not(:last-child) {
    margin-right: 1.25rem;
  }

  &[data-state="active"] {
    cursor: default;
    font-weight: 700;
  }
`;
