import styled, { createGlobalStyle, keyframes } from "styled-components";

const fadeModal = keyframes`
from {
  opacity: 0;
  transform: scale(0.8);
}

to {
  opacity: 1;
  transform: scale(1);
}
`;

const fadeModalWrapper = keyframes`
from {
  opacity: 0;
}

to {
  opacity: 1;
}
`;

export const GlobalStyledModal = createGlobalStyle`
  .modal-ready {
    opacity: 0;
    transform: scale(0.8);
  }

   .modal-wrapper-ready {
    opacity: 0;
  }
`;

export const StyledModal = styled.div`
  max-width: 1280px;
  height: auto;
  position: relative;
  max-height: 100%;
  border-radius: 10px;
  background-color: white;
  padding: 5vh 75px;
  display: flex;
  justify-content: center;

  animation: ${fadeModal} 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  transform: scale(1);
`;

export const StyledModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15;
  padding: 3vh 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: ${fadeModalWrapper} 0.3s ease-out;
  transition: all 0.3s ease-out;
  background-color: rgba(000, 000, 000, 0.9);
`;

export const StyledModalClose = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  width: 18px;
  height: 18px;
  object-fit: contain;
  cursor: pointer;
`;
