import Image from "../../images/banners/calendar-banner.jpg";
import { Breadcrumbs, Banner, Container, CalendarItem } from "storybook";
import { useLocation } from "react-router-dom";

const Calendar = () => {
  const location = useLocation();

  return (
    <>
      <Banner cover={Image} />
      <Container>
        <Breadcrumbs location={location} />
        <CalendarItem />
      </Container>
    </>
  );
};

export default Calendar;
