import { Box, Heading, Paragraph, ResponsiveContext, Text } from "grommet";
import styled from "styled-components";
import { useContext } from "react";

import { Container, NewsSubSection } from "storybook";

import { isMobileTabletResponsive } from "../../utils/utils";
import { useDataStore } from "../../hooks/useDataStore";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { app_api } from "../../api";

const StyledBox = styled(Box)`
  line-height: 1.5;

  * + li {
    margin-top: 0.8rem;
  }
`;

const Article = observer(({ children, coverBack, title, desc, date }) => {
  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);

  const { newsList } = useDataStore();

  return (
    <StyledBox>
      <Box
        as="section"
        background={{ image: `url(${coverBack})`, color: `rgba(0, 0, 0, 0.5)` }}
        style={{ backgroundBlendMode: `multiply` }}
        pad={{ top: `xlarge`, bottom: `xlarge` }}
        height={{ min: isMobileWidth ? `500px` : `740px` }}
      >
        <Box
          flex={{ grow: 1 }}
          width={`1240px`}
          pad={{ horizontal: `medium` }}
          margin={{ horizontal: `auto` }}
        >
          <Box direction="row" align="center" justify="between" margin={{ bottom: `large` }}>
            <Text size={`medium`} margin={{ right: `medium` }}>
              {date}
            </Text>
            {
              //<Button primary color="#FFFFFF">Поделиться</Button>
            }
          </Box>
          <Heading level={1} margin={{ top: `auto` }} size={`medium`}>
            {title}
          </Heading>
          {size !== `small` && (
            <Paragraph margin={{ top: `medium` }} style={{ maxWidth: `800px` }}>
              {desc}
            </Paragraph>
          )}
        </Box>
      </Box>

      <Container>
        <Box
          as={`article`}
          style={{
            fontSize: `16px`,
            paddingTop: `20px`,
            paddingBottom: `20px`,
            marginBottom: "1rem",
          }}
        >
          {children}
        </Box>

        <Box
          fill
          // margin={{ bottom: `72px` }}
          gap="24px"
          flex
          direction="column"
        >
          <Heading level={2} size={`32px`} margin={{ bottom: `medium` }}>
            Читайте также
          </Heading>
          <NewsSubSection newsList={newsList} app_api={app_api} as={Link} />
          {/*<Subscribe />*/}
        </Box>
      </Container>

      {/* <Calendar /> */}
    </StyledBox>
  );
});
export default Article;
