import banner from "../../images/home/Default.jpg";
import { Breadcrumbs, Banner, Container } from "storybook";
import { useLocation } from "react-router-dom";

const MediaPhoto = () => {
  const location = useLocation();

  return (
    <>
      <Banner cover={banner} />
      <Container>
        <Breadcrumbs location={location} />
      </Container>
    </>
  );
};

export default MediaPhoto;
