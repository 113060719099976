import Auth from "../pages/Auth";
import Cabinet from "../pages/Cabinet";
import { SignIn } from "../pages/Auth/SignIn";
import { AUTH, NO_AUTH } from "./user";

export const routes = {
  main: {
    path: "/",
    name: "Главная",
  },
  memberReg: {
    path: "/memberRegistration",
    name: "Регистрация участника",
  },
  news: {
    path: "/news",
    name: "Новости",
  },
  events: {
    path: "/events",
    name: "Мероприятия",
  },
  calendar: {
    path: "/calendar",
    name: "Календарь мероприятий",
  },
  federation: {
    path: "/federation",
    name: "Цели и задачи",
  },
  management: {
    path: "/management",
    name: "Руководство",
  },
  documents: {
    path: "/documents",
    name: "Документы",
  },
  accreditation: {
    path: "/accreditation",
    name: "Документы",
  },
  contacts: {
    path: "/contacts",
    name: "Контакты",
  },
  triathlonDepartments: {
    path: "/triathlon-departments",
    name: "Отделения",
  },
  mainCast: {
    path: "/main-cast",
    name: "Спортсмены",
  },
  coachingStaff: {
    path: "/coaching-staff",
    name: "Тренеры",
  },
  results: {
    path: "/results",
    name: "Результаты",
  },
  mediaPhoto: {
    path: "/media-photo",
    name: "Фото",
  },
  mediaVideo: {
    path: "/media-video",
    name: "Видео",
  },
  antiDoping: {
    path: "/antidoping",
    name: "Антидопинг",
  },
  congratulationsPage: {
    path: "/congratulations-page",
    name: "Поздравления",
  },
  basket: {
    path: "/basket",
    name: "Корзина",
  },
  howToStart: {
    path: "/howToStart",
    name: "С чего начать",
  },
  triathlonHistory: {
    path: "/triathlonHistory",
    name: "История триатлона",
  },
  auth: {
    path: "/signup",
    name: "Регистрация",
  },
  signIn: {
    path: "/signin",
    name: "Авторизация",
  },
  me: {
    path: "/me",
    name: "Личный кабинет",
  },
  myEvents: {
    path: "/myevents",
    name: "Мои мероприятия",
  },
  education: {
    path: "/education",
    name: "Образование",
  },
  maintenanceError: {
    path: "/maintenanceError",
    name: "Ведутся технические работы",
  },
};

export const privateRoutes = [
  {
    id: routes.auth.name,
    path: routes.auth.path,
    status: NO_AUTH,
    pathRedirect: routes.myEvents.path,
    element: <Auth />,
  },
  {
    id: routes.signIn.name,
    path: routes.signIn.path,
    status: NO_AUTH,
    pathRedirect: routes.myEvents.path,
    element: <SignIn />,
  },
  {
    id: routes.me.name,
    path: routes.me.path,
    status: AUTH,
    pathRedirect: routes.auth.path,
    element: <Cabinet />,
  },
];
