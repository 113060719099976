export const departments = [
  {
    id: 1,
    name: "ГБПОУ «МССУОР №2 Москомспорта",
    phone: "+7-916-659-00-44",
    email: "mostriathlon@mail.ru",
    link: "uor2.mossport.ru",
  },
  {
    id: 2,
    name: "ГБУ «СШОР «Северный» Москомспорта",
    phone: "+7-977-621-98-48",
    email: "bagazeev2000@mail.ru",
    link: "severny.mossport.ru",
  },
  {
    id: 3,
    name: "Детская школа триатлона - Лидер",
    phone: "+7-916-796-60-04",
    email: "mostriathlon@mail.ru",
    link: "tri-leader.ru",
  },
  {
    id: 4,
    name: 'ДЮСШ "Акватикс"',
    phone: "+7-926-527-26-14",
    email: "info@aquatics-sport.com",
    link: "aquatics-sport.com",
  },
  {
    id: 5,
    name: 'ДЮСШ "ТРИАТЛОНиЯ"',
    phone: "+7-925-316-07-23",
    email: "voynova18@yandex.ru",
    link: "триатлония.рф",
  },
];
