import registrationNKO from "./documents_pdf/Свидетельство о госрегистрации регистрации НКО РСОО МФТ.PDF";
import registrationDocument from "./documents_pdf/Свидетельство о госрегистрации регистрации ЮЛ РСОО МФТ.PDF";
import taxDocument from "./documents_pdf/Свидетельство о постановке на учет ЮЛ РСОО МФТ.PDF";
import charter from "./documents_pdf/Устав РСОО МФТ — для публикации.pdf";
import accreditation from "./documents_pdf/accreditation.pdf";

export const documentsLinks = [
  {
    title: "Свидетельство о госрегистрации регистрации НКО РСОО МФТ.pdf",
    path: registrationNKO,
  },
  {
    title: "Свидетельство о госрегистрации регистрации ЮЛ РСОО МФТ.pdf",
    path: registrationDocument,
  },
  {
    title: "Свидетельство о постановке на учет ЮЛ РСОО МФТ.pdf",
    path: taxDocument,
  },
  {
    title: "Устав РСОО МФТ — для публикации.pdf",
    path: charter,
  },
  {
    title: "Аккредитация",
    path: accreditation,
  },
];
