import { useState } from "react";
import { SignUpForm, SignUpType, Breadcrumbs, Banner, Container } from "storybook";
import bannerImg from "../../images/home/Default.jpg";
import { useUserStore } from "../../hooks/useUserStore";
import { observer } from "mobx-react";
import { Link, useLocation } from "react-router-dom";

const Auth = observer(() => {
  const [signUpType, setSignUpType] = useState("");
  const location = useLocation();

  const { isSendedMail, authError, setAuthError, setSendMail, registration } = useUserStore();

  return (
    <>
      <Banner cover={bannerImg} />

      <Container>
        <Breadcrumbs location={location} />

        {signUpType === "" && <SignUpType setSignUpType={setSignUpType} />}

        {signUpType === "sportsman" || signUpType === "organization" ? (
          <SignUpForm
            as={Link}
            isSendedMail={isSendedMail}
            authError={authError}
            setAuthError={setAuthError}
            setSendMail={setSendMail}
            registration={registration}
          />
        ) : null}
      </Container>
    </>
  );
});

export default Auth;
