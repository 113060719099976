import { Select } from "grommet";
import styled from "styled-components";
import { IconSvg } from "storybook";

export const StyledForm = styled.form`
  margin-top: 40px;
`;

export const StyledRadioButtons = styled.div`
  display: flex;
  column-gap: 20px;
  opacity: ${(props) => props.disabled && "0.5"};
  pointer-events: ${(props) => (props.disabled ? "none" : "all")};
`;
export const StyledSelect = styled(Select)`
  width: 345px;
  padding: 21px 40px;
`;
export const StyledDiv = styled.div`
  & > .jOVKdY {
    border-color: black;
    border-color: ${(props) => props.error && "#CA3C3C"};
  }
`;
export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 17.25rem;
  text-decoration: none;
  width: 100%;
  font-size: 0.75rem;
  box-shadow: 0 -0.438rem 1.875rem -0.875rem #982eeb;

  color: white;
  border: none;
  cursor: pointer;

  font-size: 0.75rem;
  line-height: 1.166;
  width: 85%;
  border-radius: 0.3125rem;

  max-width: 15rem;
  min-height: 2.5rem;
  border-radius: 1.875rem;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  box-shadow: 0 -0.438rem 1.875rem -0.875rem #982eeb;
  overflow: hidden;
  transition: all 0.25s ease;

  font-size: 0.75rem;
  max-width: 17.25rem;
  width: 100%;

  background: linear-gradient(267.53deg, #982eeb -0.27%, #359ad2 100%), #c7252b;
`;
export const StyledButtonCancelReg = styled.button`
  border: unset;
  border-radius: 6rem;
  padding: 0.8rem;
`;
export const StyledPopupButton = styled.button`
  border: unset;
  border-radius: 4px;
  padding: 0.8rem;
`;
export const StyledPopupContent = styled.div`
  padding: 4rem;
`;
export const StyledPopupButtons = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  column-gap: 2rem;
`;
export const StyledParagraph = styled.p`
  line-height: 1.2;
  margin-bottom: 10px;
  color: ${(props) => props.error && "#CA3C3C"};
  opacity: ${(props) => props.disabled && "0.5"};
`;
export const StyledTitle = styled.h2`
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 1rem;
`;

export const StyledFormWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(23.75rem, 1fr));
  gap: 2.5rem 1.5rem;

  & + * {
    margin-top: 2.125rem;
  }
`;
export const StyledFooter = styled.div`
  display: flex;
  column-gap: 30px;
  align-items: center;
`;
export const StyledText = styled.p`
  line-height: 1.2;
  font-size: 0.7rem;
`;
export const StyledA = styled.a`
  color: black;
`;
export const StyledTransferA = styled(StyledButton)`
  text-decoration: none;
  margin-top: 3rem;
  color: white;
`;
export const StyledErrorMessage = styled.div`
  font-size: 0.8rem;
  line-height: 1.2;
  margin-bottom: 10px;
`;
export const StyledCancelMessage = styled.h2`
  margin-bottom: 2rem;
`;
export const StyledBox = styled.div`
  position: relative;
  & > .jOVKdY {
    border-color: black;
    border-color: ${(props) => props.error && "#CA3C3C"};
  }
`;
export const StyledIconSvg = styled(IconSvg)`
  position: absolute;
  left: 14px;
  top: 20px;
  color: ${(props) => props.error && "#CA3C3C"};
  opacity: ${(props) => props.disabled && "0.5"};
`;
export const StyledErrorRegMessage = styled.h2`
  margin-bottom: 2rem;
  color: #ca3c3c;
`;
