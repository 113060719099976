import * as yup from "yup";

export function makeValidationSchema(isSize, isRegion, isTeams) {
  return yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    size: isSize ? yup.string().required("Выберите размер!") : yup.string(),
    region: isRegion ? yup.string().required("Выберите регион!") : yup.string(),
    team: isTeams ? yup.string().required("Выберите команду!") : yup.string(),
    birth: yup
      .string()
      .required()
      .test("isValidBirth", "Введите корректную дату!", (value) => {
        const newDate = value && value.split(`.`).reverse().join(`-`);
        if (value && new Date(newDate).toLocaleDateString("en-GB") === `Invalid Date`) return false;
        if (value && value.length >= 10) return true;
      }),
    phone: yup.string().test("isValidPhone", "Введите корректный номер телефона", (value) => {
      if (!value || value.length !== 12) return false;
      if (value && value.length === 12) return true;
    }),
  });
}
