import { routes } from "../../const/routes";
import backImage from "../../images/home/slider/slider4.jpg";
import backImage2 from "../../images/home/slider/slider2.jpg";
import backImage3 from "../../images/home/slider/slider3.jpg";
import backImage4 from "../../images/home/slider/slider1.jpg";

export const mainPageSlides = [
  {
    id: 1,
    date: `05.03.2021`,
    title: `Добро пожаловать на обновленный сайт Федерации Триатлона Москвы`,
    desc: ``,
    image: backImage,
    link: `/`,
    btnText: ``,
    isButton: false,
    outside: false,
  },
  {
    id: 2,
    date: `05.03.2021`,
    title: `Как начать?`,
    desc: `Хотите пройти свой первый триатлон, но не знаете, с чего начать?`,
    image: backImage2,
    link: routes.howToStart.path,
    btnText: `Читать далее`,
    isButton: true,
    outside: false,
  },
  {
    id: 3,
    date: `05.03.2021`,
    title: `Календарь стартов 2022`,
    desc: `Актуальный календарь триатлонных стартов на всей территории Москвы и области`,
    image: backImage3,
    link: `${routes.calendar.path}`,
    btnText: `Посмотреть`,
    isButton: true,
    outside: false,
  },
  {
    id: 4,
    date: `05.03.2021`,
    title: `История триатлона`,
    desc: ``,
    image: backImage4,
    link: routes.triathlonHistory.path,
    btnText: `Посмотреть`,
    isButton: true,
    outside: false,
  },
];
