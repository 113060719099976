import * as yup from "yup";
import { REGEX_MAIL } from "../../const/regex";

export function makeValidationSchema() {
  return yup.object().shape({
    email: yup
      .string()
      .required()
      .test("emailIslValid", "Введите валидный email", (value) => {
        const testValue = REGEX_MAIL.test(value);

        if (!testValue) return false;
        if (testValue) return true;
      }),
  });
}
