export const setOptions = (arr, option = `title`) => {
  if (arr.length > 0) {
    return arr.map((el) => {
      return { value: el[option], label: el[option] };
    });
  }
  return [];
};

export const setEventsOptions = (arr, value = `public_id`, label = `title`) => {
  if (arr.length > 0) {
    return arr.map((el) => {
      return { value: el[value], label: el[label] };
    });
  }
  return [];
};

export const setTeamsOptions = (arr, value = `public_id`, label = `title`, ticket) => {
  if (arr.length > 0) {
    return arr.map((el) => {
      return {
        value: el[value],
        label: `${el[label]} (свободно ${el.tickets_left} мест из ${
          (ticket && ticket.event_format.max_count && ticket.event_format.max_count) || "10"
        })`,
      };
    });
  }
  return [];
};

export const setCountOptions = (arr, option = `name`, count = `count`) => {
  let options = [],
    index = 0;

  if (arr && arr.length > 0) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][count] === 0) continue;
      else {
        options[index] = { value: arr[i][option], label: arr[i][option] };
        index++;
      }
    }
  } else {
    return [];
  }

  return options;
};

export const setDeliveryOptions = (arr, value = "id", name = "name_ru") => {
  if (arr.length > 0) {
    return arr.map((el) => {
      return { value: el[value], label: el[name], id: el.id };
    });
  }
  return [];
};

export const optionsCity = [
  { value: "Москва", label: "Москва" },
  { value: "Санкт-Петербург", label: "Санкт-Петербург" },
  { value: "Воронеж", label: "Воронеж" },
  { value: "Тула", label: "Тула" },
  { value: "Владивосток", label: "Владивосток" },
  { value: "Красноярск", label: "Красноярск" },
  { value: "Чита", label: "Чита" },
];

export const optionsEvents = [
  { value: "Гонка Героев", label: "Гонка Героев" },
  { value: "Арена Героев", label: "Арена Героев" },
  { value: "Гонка Героев Зима", label: "Гонка Героев Зима" },
];

export const optionsSports = [
  { value: "Гонка Героев", label: "Гонка Героев" },
  { value: "Арена Героев", label: "Арена Героев" },
  { value: "Гонка Героев Зима", label: "Гонка Героев Зима" },
];

export const optionsPerson = [
  { value: "Партнер", label: "Партнер" },
  { value: "Родитель", label: "Родитель" },
  { value: "Родственник", label: "Родственник" },
  { value: "Друг ", label: "Друг " },
  { value: "Коллега", label: "Коллега" },
];

export const optionsSize = [
  { value: "XS", label: "XS" },
  { value: "S", label: "S" },
  { value: "M", label: "M" },
  { value: "L", label: "L" },
  { value: "XL", label: "XL" },
  { value: "XXL", label: "XXL" },
  { value: "Детский, рост 140", label: "Детский, рост 140" },
  { value: "Детский, рост 152", label: "Детский, рост 152" },
];
