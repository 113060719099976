import React, { useState, useEffect } from "react";
import {
  StyledAddBlock,
  StyledButton,
  StyledCertificate,
  StyledCost,
  StyledCostWrap,
  StyledCountButton,
  StyledDeleteButton,
  StyledErrorText,
  StyledInfoBlock,
  StyledInfoCount,
  StyledInfoCountText,
  StyledInfoFormat,
  StyledInfoLocation,
  StyledInfoText,
  StyledInfoTitle,
  StyledInfoWrap,
  StyledInput,
  StyledInsideItem,
  StyledInsideLocation,
  StyledInsideTitle,
  StyledLogo,
  StyledLogoWrap,
  StyledPromoCodeBlock,
  StyledWrapper,
} from "./styles";

import { getTotalCost, setDiscountPrice } from "../../../utils/goods";
import { BASKET_COUNT } from "../../../const/const";
import { updateLocalStorage } from "../../../utils/utils";
import { useForm } from "react-hook-form";
import { observer } from "mobx-react";

export const Goods = observer(
  ({
    item,
    usedTicket,
    userObj,
    removeShop,
    isVipUser,
    updateGoods,
    promoCodeError,
    sendPromoCode,
    promoCodes,
    removePromoCode,
    checkTicketsBeforePaymentByFormatId,
  }) => {
    const [promoCodeApplied, setPromoCodeApplied] = useState(false);
    const ITEM_ID = item.public_id;

    const {
      register,
      handleSubmit,
      clearErrors,
      setError,
      setValue,
      formState: { errors },
    } = useForm({ shouldFocusError: false });

    const basketTicketHandler = (evt) => {
      evt.preventDefault();

      const target = evt.target.dataset.ticketBtn;
      let count = item.count;
      const ticketLeft = item.tickets_left;

      let min_count = item.buy_limit ? item.buy_limit : BASKET_COUNT.min_count;
      let max_count = (item.buy_limit && item.buy_limit) || ticketLeft || BASKET_COUNT.max_count;

      if (item.team_all) {
        min_count = item?.fixed_count === false ? BASKET_COUNT.min_count : item.max_count;
        max_count = item.max_count * BASKET_COUNT.max_count;
      }
      let totalCost = item.totalCost;

      if (target === "increment") {
        count = count >= max_count ? max_count : count + min_count;
      } else if (target === "decrement") {
        count = count <= min_count ? min_count : count - min_count;
      }
      totalCost =
        isVipUser && usedTicket === undefined && item.selector === "ticket"
          ? getTotalCost(
              setDiscountPrice(item.price, userObj.discount, userObj.discount_form),
              1,
              item.insurance && item.insurance_cost
            ) + getTotalCost(item.price, count - 1, item.insurance && item.insurance_cost)
          : getTotalCost(item.price, count, item.insurance && item.insurance_cost);

      const newObj = { ...item, totalCost, count };

      updateGoods(newObj);
      updateLocalStorage("basketGoods", "add", newObj);
    };

    const handleSubmitForm = (data) => {
      if (!data[ITEM_ID].length) {
        setError(ITEM_ID, {
          type: "custom",
          message: "Введите промокод",
        });
        return;
      }
      const codeInfo = { code: data[ITEM_ID], ...item };
      sendPromoCode(codeInfo);
    };

    const handleRemoveCode = (event) => {
      event.preventDefault();
      setPromoCodeApplied(false);
      setValue(ITEM_ID, "");
      removePromoCode(item);
    };

    useEffect(() => {
      if (promoCodeError.message && promoCodeError.id === item.public_id) {
        setError(ITEM_ID, {
          type: "custom",
          message: promoCodeError.message,
        });
      }
    }, [promoCodeError]);

    useEffect(() => {
      if (promoCodes.length) {
        setPromoCodeApplied(!!promoCodes.find((el) => el.public_id === item.public_id));
      }
    }, [promoCodes]);
    useEffect(() => {
      checkTicketsBeforePaymentByFormatId(item.public_id);
    }, [item]);

    return (
      <form onSubmit={promoCodeApplied ? handleRemoveCode : handleSubmit(handleSubmitForm)}>
        <StyledWrapper>
          <StyledLogoWrap>
            <StyledLogo src={item.logo} alt="Гонка Героев" />
          </StyledLogoWrap>
          <StyledInfoBlock>
            {item.event && item.event.title && (
              <StyledInfoTitle>{item.event.title}</StyledInfoTitle>
            )}
            <StyledInfoWrap>
              <StyledInfoLocation>
                <b>{item.selector === `shop` ? "Продукт" : "Локация"}:</b>
                <StyledInfoText>
                  {item.selector === `shop` ? item.title : item.address}
                </StyledInfoText>
              </StyledInfoLocation>
              <StyledInfoFormat>
                <b>
                  {item.selector === `shop`
                    ? item.size
                      ? `${"Размер"} :`
                      : null
                    : `${"Формат"} :`}
                </b>
                <StyledInfoText>{item.selector === `shop` ? item.size : item.title}</StyledInfoText>
              </StyledInfoFormat>
              <StyledInfoCount>
                <b>Количество:</b>
                <StyledInfoCountText>
                  <StyledCountButton
                    type="button"
                    data-ticket-btn="increment"
                    onClick={basketTicketHandler}
                  >
                    +
                  </StyledCountButton>
                  <span>{item.count}</span>
                  <StyledCountButton
                    type="button"
                    data-ticket-btn="decrement"
                    onClick={basketTicketHandler}
                  >
                    -
                  </StyledCountButton>
                </StyledInfoCountText>
              </StyledInfoCount>
              <StyledCostWrap>
                <b>Стоимость:</b>
                <StyledCost>{(item.price * item.count).toFixed(0)} &#8381;</StyledCost>
              </StyledCostWrap>
            </StyledInfoWrap>
            {item.insurance && (
              <StyledInsideItem>
                <StyledInsideLocation>
                  <StyledInsideTitle>Дополнительно:</StyledInsideTitle>
                  <StyledInfoText>Страхование участника</StyledInfoText>
                </StyledInsideLocation>
                <StyledInfoCount>
                  <StyledInsideTitle>Количество:</StyledInsideTitle>
                  <StyledCost>
                    <span>{item.count}</span>
                  </StyledCost>
                </StyledInfoCount>
                <StyledCostWrap>
                  <StyledCost>{item.insurance_cost * item.count} &#8381;</StyledCost>
                </StyledCostWrap>
              </StyledInsideItem>
            )}
          </StyledInfoBlock>

          <StyledAddBlock>
            <StyledPromoCodeBlock>
              <StyledInput
                promoCodeApplied={promoCodeApplied}
                errors={errors[ITEM_ID]}
                type="text"
                {...register(ITEM_ID)}
                placeholder={"Есть промокод?"}
                onFocus={() => clearErrors(ITEM_ID)}
              />
              {errors[ITEM_ID] && <StyledErrorText>{errors[ITEM_ID].message}</StyledErrorText>}

              <StyledButton type="submit">
                {!promoCodeApplied ? "Применить" : "Изменить"}
              </StyledButton>
            </StyledPromoCodeBlock>

            <StyledCertificate>
              На все наши мероприятия необходимо иметь при себе медицинскую справку
            </StyledCertificate>
          </StyledAddBlock>
          <StyledDeleteButton onClick={() => removeShop(item)}>Удалить</StyledDeleteButton>
        </StyledWrapper>
      </form>
    );
  }
);
