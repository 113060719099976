import { useLocation } from "react-router-dom";
import { Breadcrumbs, Banner, Container, EducationItem } from "storybook";
import Image from "../../images/banners/education-banner.jpg";

const Education = () => {
  const location = useLocation();

  return (
    <>
      <Banner cover={Image} />
      <Container>
        <Breadcrumbs location={location} />
        <EducationItem />
      </Container>
    </>
  );
};

export default Education;
