import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import Image from "../../images/home/Default.jpg";
import { Breadcrumbs, Banner, Container, Activity } from "storybook";
import { observer } from "mobx-react";
import { useDataStore } from "../../hooks/useDataStore";
import { allYearEvents } from "../../mocks/time";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTicketsStore } from "../../hooks/useTicketsStore";
import { useBasketStore } from "../../hooks/useBasketStore";
import { usePaymentStore } from "../../hooks/usePaymentStore";
import { getFilterMonths } from "../../utils/calendar";
import {
  StyledAside,
  StyledFilters,
  StyledItem,
  StyledList,
  StyledMain,
  StyledMainContent,
  StyledSelect,
} from "./styles";
import { Select } from "grommet";
import { setOptions } from "../../mocks/calendarOptions";

const defaultCities = { id: `all`, name_ru: `Все города` };
const defaultTypes = { title: `Все мероприятия` };
const initSendObj = {
  city: { id: 0 },
  date_from: allYearEvents.date_from,
  date_to: allYearEvents.date_to,
  event_type: { public_id: null },
};

export const Events = observer(() => {
  const location = useLocation();
  const params = useParams();
  const [city, setCity] = useState(``);
  const [activity, setActivity] = useState(``);
  const [monthActive, setMonthActive] = useState(`Все`);
  const [months, setMonths] = useState([]);
  const [filterMonths, setFilterMonths] = useState([]);

  const promocode = params?.promocode;

  const {
    loadFilteredEvents,
    filteredEvents,
    loadFormats,
    formats,
    setLoadFormats,
    eventType,
    eventCities,
    loadEventCities,
    loadEventType,
    cleanFormats,
  } = useDataStore();

  const { loadTickets, tickets } = useTicketsStore();

  const { setPromoCode } = usePaymentStore();

  const { addShop } = useBasketStore();

  const sendObj = useRef({ ...initSendObj });

  useEffect(() => {
    cleanFormats([]); // # Для отрисовки при переключении на события
    if (sendObj.current) {
      loadFilteredEvents(sendObj.current);
    }
  }, []);

  useEffect(() => {
    if (!eventCities.length) loadEventCities();
  }, [eventCities]);

  useEffect(() => {
    if (!eventType.length) loadEventType();
  }, [eventType]);

  useEffect(() => localStorage.removeItem("fEvents"), []); // # Временное решение (если в LS не актуальная информация)

  useLayoutEffect(() => {
    if (localStorage.getItem("fEvents")) {
      // забрать значение из LS
      const eventsForMonths = JSON.parse(localStorage.getItem("fEvents"));
      setFilterMonths(getFilterMonths(eventsForMonths));
    }

    if (filteredEvents.length > 0 && !localStorage.getItem("fEvents")) {
      localStorage.setItem("fEvents", JSON.stringify(filteredEvents));
      setFilterMonths(getFilterMonths(filteredEvents));
    }

    if (filteredEvents.length > 0) {
      const months = getFilterMonths(filteredEvents);
      setMonths(months);
    }
  }, [filteredEvents]);

  const handleActivityFilter = (value, param, data = {}) => {
    if (param === `city`) {
      const city = eventCities.find((el) => el.name_ru === value);
      if (city) {
        setCity(value);
        sendObj.current.city = { id: city.id };
      } else {
        setCity(``);
        sendObj.current.city = { id: 0 };
      }
    }

    if (param === `activity`) {
      const activity = eventType.find((el) => el.title === value);
      if (activity) {
        setActivity(value);
        sendObj.current.event_type = { public_id: activity.public_id };
      } else {
        setActivity(``);
        sendObj.current.event_type = { public_id: null };
      }
    }
    if (param === `filter`) {
      setMonthActive(`${data.month}/${data.year}`);
      sendObj.current.date_from = data.date_from;
      sendObj.current.date_to = data.date_to;
    }

    if (!sendObj.current.city) sendObj.current.city = { id: 0 };
    if (!sendObj.current.event_type) sendObj.current.event_type = { public_id: null };
    if (sendObj.current) {
      loadFilteredEvents(sendObj.current);
    }
  };

  return (
    <>
      <Banner cover={Image} />
      <Container>
        <Breadcrumbs location={location} />
        <StyledMain>
          <StyledAside>
            <StyledFilters>
              <StyledSelect
                data-filter="city"
                onChange={({ value: nextValue }) => handleActivityFilter(nextValue, "city")}
                value={city}
                id="city"
                name="city"
                valueKey={{ key: "value", reduce: true }}
                labelKey="label"
                options={setOptions(
                  eventCities?.length ? [defaultCities, ...eventCities] : [defaultCities],
                  "name_ru"
                )}
                placeholder="Выберите ваш город"
              />
              <StyledSelect
                data-filter="activity"
                onChange={({ value: nextValue }) => handleActivityFilter(nextValue, "activity")}
                value={activity}
                valueKey={{ key: "value", reduce: true }}
                labelKey="label"
                id="activity"
                name="activity"
                options={setOptions(
                  eventType?.length ? [defaultTypes, ...eventType] : [defaultTypes]
                )}
                placeholder="Выбрать мероприятие"
              />
            </StyledFilters>
          </StyledAside>
          <StyledMainContent>
            <StyledList>
              <StyledItem
                onClick={(data) => handleActivityFilter(data, "filter", allYearEvents)}
                data-state={monthActive === "Все" ? "active" : ""}
              >
                {allYearEvents.month}
              </StyledItem>

              {filterMonths.map((item, i) => (
                <StyledItem
                  key={`${item.month}${i}`}
                  data-state={monthActive === `${item.month}/${item.year}` ? "active" : ""}
                  onClick={(data) => handleActivityFilter(data, "filter", item)}
                >{`${item.month} ${item.year}`}</StyledItem>
              ))}
            </StyledList>
            <Activity
              events={filteredEvents}
              months={months}
              promocode={promocode}
              urlParams={params}
              formats={formats}
              loadFormats={loadFormats}
              cleanFormats={setLoadFormats}
              as={Link}
              loadTickets={loadTickets}
              allTickets={tickets}
              addShop={addShop}
              getPromocode={setPromoCode}
            />
          </StyledMainContent>
        </StyledMain>
      </Container>
    </>
  );
});
