import { setDeliveryOptions } from "../mocks/calendarOptions";

// функция установки региона доставки через location
export const searchDeliveryRegion = (arr, id, option) => {
  if (arr.length) {
    switch (option) {
      case "region":
        return setDeliveryOptions(arr).find((el) => el.id === id);
      case "city":
        return setDeliveryOptions(arr).find((el) => el.id === id);
      default:
        break;
    }
  }
};
