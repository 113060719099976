import { Anchor, Box, Image, List } from "grommet";
import ImageBanner from "../../images/banners/federation-banner.jpg";
import { documentsLinks } from "../../mocks/documents";
import pdfIcon from "../../Icons/pdf.png";
import { Breadcrumbs, Banner, Container } from "storybook";
import { useLocation } from "react-router-dom";

const Documents = () => {
  const location = useLocation();

  return (
    <>
      <Banner cover={ImageBanner} />
      <Container>
        <Breadcrumbs location={location} />
        <List data={documentsLinks} border={false}>
          {(document) => (
            <Box direction="row-responsive" gap="medium" align="center">
              <Box direction="row">
                <Image src={pdfIcon} width={`55`} />
              </Box>
              <Anchor
                href={document.path}
                label={document.title}
                target={`_blank`}
                rel={`noopener noreferrer`}
                style={{
                  fontFamily: "Gotham Pro",
                  fontSize: `16px`,
                  textDecoration: "none",
                }}
              />
            </Box>
          )}
        </List>
      </Container>
    </>
  );
};

export default Documents;
