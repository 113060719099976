import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { makeValidationSchema } from "./makeValidationSchema";
import { SquareCheckBox, DefaultButton, TextInput } from "storybook";
import { StyledErrorTitle, StyledForm } from "./styles";

export const TransferTicketForm = ({ ticket, email, transferTicketMessage, sendTicket }) => {
  const [isValidForm, setValidForm] = useState(false);
  const [isCheckPolitics, setCheckPolitics] = useState(false);

  const validateForm = makeValidationSchema();

  useEffect(() => {
    setValidForm(isCheckPolitics);
  }, [isCheckPolitics]);

  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    control,
  } = useForm({
    resolver: yupResolver(validateForm),
    shouldFocusError: false,
  });

  const onSubmit = ({ email }) => {
    sendTicket({ public_id: ticket.public_id, email });
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      {transferTicketMessage && <StyledErrorTitle>{transferTicketMessage}</StyledErrorTitle>}

      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextInput
            error={errors.email?.message}
            handleFocus={() => clearErrors("email")}
            name="email"
            iconSrc="email"
            iconWidth="20px"
            iconTop="16px"
            iconLeft="14px"
            id="transfer-email"
            label="Передать билет по Email"
            {...field}
          />
        )}
      />
      <DefaultButton width="400px" type="submit" disabled={!isValidForm} caption="Передать" />

      <SquareCheckBox
        onChange={(e) => setCheckPolitics(e.target.checked)}
        label="Подтверждаю что email введен верно"
        checked={isCheckPolitics}
      />
    </StyledForm>
  );
};
