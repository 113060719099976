import { createRoot } from "react-dom/client";
import "./index.css";
import "swiper/swiper-bundle.css";
import App from "./App";
import { createIOC } from "./bindings";
import { iocContext } from "./hooks/useInject";
import { initYupMessages } from "./const/initYupMessages";
import { BrowserRouter } from "react-router-dom";

const ioc = createIOC();
initYupMessages();

const root = createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <iocContext.Provider value={ioc}>
      <App />
    </iocContext.Provider>
  </BrowserRouter>
);
