import "reflect-metadata";
import { Container } from "inversify";
import { iocNames } from "./iocNames";
import { DataStore } from "./stores/DataStore";
import { SubscribeStore } from "./stores/SubscribeStore";
import { AntiDopingStore } from "./stores/AntiDopingStore";
import { ProfileStore } from "./stores/ProfileStore";
import { UserStore } from "./stores/UserStore";
import { additionalApi, api } from "./api";
import { TicketsStore } from "./stores/TicketsStore";
import { BasketStore } from "./stores/BasketStore";
import { PaymentStore } from "./stores/PaymentStore";
import { MemberRegistrationStore } from "./stores/MemberRegistrationStore";
import { MembersStore } from "./stores/MembersStore";

export function createIOC() {
  const ioc = new Container({ skipBaseClassChecks: true });

  ioc.bind(iocNames.api).toConstantValue(api);
  ioc.bind(iocNames.additionalApi).toConstantValue(additionalApi);

  ioc
    .bind(iocNames.dataStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new DataStore(api);
    })
    .inSingletonScope();

  ioc
    .bind(iocNames.antiDopingStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      const additionalApi = container.get(iocNames.additionalApi);
      return new AntiDopingStore(api, additionalApi);
    })
    .inSingletonScope();

  ioc
    .bind(iocNames.subscribeStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new SubscribeStore(api);
    })
    .inSingletonScope();
  ioc
    .bind(iocNames.profileStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new ProfileStore(api);
    })
    .inSingletonScope();
  ioc
    .bind(iocNames.userStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new UserStore(api);
    })
    .inSingletonScope();
  ioc
    .bind(iocNames.ticketsStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new TicketsStore(api);
    })
    .inSingletonScope();
  ioc
    .bind(iocNames.basketStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new BasketStore(api);
    })
    .inSingletonScope();
  ioc
    .bind(iocNames.paymentStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new PaymentStore(api);
    })
    .inSingletonScope();
  ioc
    .bind(iocNames.memberRegistrationStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new MemberRegistrationStore(api);
    })
    .inSingletonScope();

  ioc
    .bind(iocNames.membersStore)
    .toDynamicValue(({ container }) => {
      const api = container.get(iocNames.api);
      return new MembersStore(api);
    })
    .inSingletonScope();

  return ioc;
}
