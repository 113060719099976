import { useContext, useEffect, useRef, useState } from "react";
import { Box, Heading, Paragraph, ResponsiveContext, Text, Anchor } from "grommet";
import Swiper, { Navigation, Pagination } from "swiper";
import { Link } from "react-router-dom";
import styled from "styled-components";

import styles from "./Loader.module.scss";
import "./HomeSlider.scss";

import { mainPageSlides } from "../../mocks/home/home";
import { isMobileTabletResponsive } from "../../utils/utils";

export const returnSliderParams = (styles) => {
  return {
    wrapperClass: styles.wrapperClass,
    slideClass: styles.slideClass,
    spaceBetween: 24,
    slidesPerView: `auto`,
    threshold: 15,

    navigation: {
      nextEl: `.${styles.btnNext}`,
      prevEl: `.${styles.btnPrev}`,
    },
  };
};

export const customSliderParams = (styles, count) => {
  return {
    ...returnSliderParams(styles),
    slidesPerView: count,
    allowTouchMove: false,
    autoplay: true,
  };
};

Swiper.use([Navigation, Pagination]);

const stylesSwiper = {
  container: `container-main`,
  wrapperClass: `wrapper-main`,
  slideClass: `slide-main`,
  nav: `nav-main`,
  btnNext: `next-btn-main`,
  btnPrev: `prev-btn-main`,
};

const styleStr = `display: inline-block;
  background-color: #DB4C4C;
  color: #f8f8f8;
  text-decoration: none;
  border-radius: 18px;
  padding: 6px 32px;
  font-size: 12px;
  line-height: 2;
  transition: box-shadow 0.2s ease;
  letter-spacing: 0.5px;

  &:focus, &:hover {
    box-shadow: 0 0 2px 2px #DB4C4C;
  }`;
const CustomLink = styled(Link)`
  ${styleStr}
`;
const CustomAnchor = styled(Anchor)`
  ${styleStr}
`;

const COUNTER = {
  size: 100,
  freq: 50,
};

const HomeSlider = () => {
  const [count, setCount] = useState(0);
  const swiper = useRef(null);
  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);

  useEffect(() => {
    swiper.current = new Swiper(
      `.${stylesSwiper.container}`,
      customSliderParams(stylesSwiper, isMobileWidth ? 1 : 1.3)
    );
  }, [size, isMobileWidth]);

  useEffect(() => {
    const timer = setInterval(counterTime, COUNTER.freq);
    return () => clearTimeout(timer);
  });

  const counterTime = () => {
    setCount((prev) => prev + 1);

    if (count >= COUNTER.size) {
      setCount(0);
      swiper.current.progress === 1
        ? swiper.current.slideTo(0, 1200)
        : swiper.current.slideNext(1200);
    }
  };

  return (
    <Box margin={{ bottom: `72px` }}>
      <Box className={stylesSwiper.container} overflow={`hidden`} fill>
        <Box className={stylesSwiper.wrapperClass} direction="row" as={`ul`} fill>
          {mainPageSlides.map((el) => (
            <Box
              key={el.id}
              as="li"
              flex={{ shrink: 0 }}
              align={`start`}
              background={{
                image: `linear-gradient(0deg, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)), url(${el.image})`,
              }}
              className={`${stylesSwiper.slideClass}`}
              pad={{
                horizontal: isMobileWidth ? `xlarge` : `142px`,
                top: isMobileWidth ? `xlarge` : `225px`,
                bottom: isMobileWidth ? `large` : `70px`,
              }}
            >
              <Text size="small">{el.date}</Text>
              <Heading
                level={1}
                size={size === "small" ? `small` : `medium`}
                margin={{ bottom: `medium`, top: `small` }}
                style={{ maxWidth: size === "small" ? `300px` : `590px` }}
              >
                {el.title}
              </Heading>
              {size !== "small" && (
                <Paragraph
                  size={`small`}
                  margin={{ bottom: `16px` }}
                  color={`rgba(255, 255, 255, 1)`}
                >
                  {el.desc}
                </Paragraph>
              )}
              {el.isButton && !el.outside && <CustomLink to={el.link}>{el.btnText}</CustomLink>}
              {el.isButton && el.outside && (
                <CustomAnchor
                  href={el.link}
                  label={`${el.btnText}`}
                  target="_blank"
                  rel="noreferrer"
                />
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        margin={{ top: `2px` }}
        fill
        align={`start`}
        background={`light-1`}
        pad={{ vertical: `2px` }}
        overflow={`hidden`}
      >
        <span
          className={styles.loader}
          style={{
            width: `${(count * 100) / COUNTER.size}%`,
            display: `block`,
            height: `12px`,
            background: `#DB4C4C`,
          }}
        ></span>
      </Box>
    </Box>
  );
};

export default HomeSlider;
