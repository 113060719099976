import axios from "axios";
import { routes } from "./const/routes";

const REACT_APP_API = process.env.REACT_APP_API;

const TIMEOUT = 10000;

export const app_api = REACT_APP_API;
export const additionalApi = `https://rustriathlon.ru`;

const Error = {
  UNAUTHORIZED: 401,
  INVALID_TOKEN: 403,
};

const createAPI = () => {
  const api = axios.create({
    baseURL: REACT_APP_API,
    timeout: TIMEOUT,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const onSuccess = (response) => response;

  const onFail = (err) => {
    const { response } = err;

    if (!response) throw err;
    else if (response.status === Error.UNAUTHORIZED || response.status === Error.INVALID_TOKEN) {
      if (response.status === Error.INVALID_TOKEN) localStorage.removeItem("token");
      window.location.href = routes.main.path;
      throw err;
    } else if (response.data) return response;

    // Если запрос вообще неудачный - бросить ошибку
    throw err;
  };

  api.interceptors.response.use(onSuccess, onFail); // перехватчик ответа

  return api;
};

export default createAPI;

export const api = createAPI();
