import styled from "styled-components";

export const StyledItem = styled.li`
  position: relative;
  flex-basis: 23.75rem;
  border-radius: 0.938rem;
  background-repeat: no-repeat;
  background-position: right;
  list-style-type: none;
  @media (max-width: 768px) {
    width: 100%;
    flex-basis: auto;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
`;
export const StyledWrapper = styled.div`
  position: relative;
  padding: 1.563rem;
  display: flex;
  width: 80%;
  min-height: 16.75rem;
  background-color: #f8f8f8;
  border-radius: 0.938rem;
  z-index: 10;
  @media (max-width: 768px) {
    width: auto;
  }
`;
export const StyledWrap = styled.div`
  max-width: 76px;
  height: 62px;
  margin-bottom: 2rem;
`;
export const StyledLogo = styled.img`
  width: 100%;
  height: auto;
`;

export const StyledInfo = styled.p`
  margin-bottom: 1.1rem;
  font-size: 0.875rem;
  font-weight: 700;
  margin-left: auto;
`;

export const StyledInfoLabel = styled.span`
  display: block;
  width: 100%;
  font-size: 0.625rem;
  font-weight: 400;
`;
export const StyledBtnHoverNoReg = styled.span`
  position: absolute;
  font-size: 0.6rem;
  line-height: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0s ease;
`;
export const StyledBtnHoverReg = styled(StyledBtnHoverNoReg)`
  opacity: 0;
`;

export const StyledAReg = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 50%;
  bottom: -1.25rem;
  transform: translateX(-50%);
  max-width: 17.25rem;
  width: 100%;
  font-size: 0.75rem;
  box-shadow: 0 -0.438rem 1.875rem -0.875rem rgba(53, 154, 210, 0.75);

  color: white;
  border: none;
  cursor: pointer;

  font-size: 0.75rem;
  line-height: 1.166;
  width: 85%;
  border-radius: 0.3125rem;

  max-width: 15rem;
  min-height: 2.5rem;
  border-radius: 1.875rem;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  box-shadow: 0 -0.438rem 1.875rem -0.875rem #359ad2;
  overflow: hidden;
  transition: all 0.25s ease;

  &:hover {
    background: linear-gradient(267.53deg, #982eeb -0.27%, #359ad2 100%);
    background-repeat: no-repeat;

    ${StyledBtnHoverNoReg} {
      opacity: 0;
    }

    ${StyledBtnHoverReg} {
      opacity: 1;
    }
  }
`;

export const StyledA = styled(StyledAReg)`
  background: linear-gradient(267.53deg, #982eeb -0.27%, #359ad2);
  background-repeat: no-repeat;
  text-decoration: none;
  &:hover {
    opacity: 0.5;
  }
`;
export const StyledAwait = styled(StyledAReg)`
  background-image: linear-gradient(90deg, #ee8d1b, #c7252b);
  cursor: default;
`;

export const StyledPast = styled(StyledAReg)`
  background: linear-gradient(267.53deg, #982eeb -0.27%, #eb2ec2);
  background-repeat: no-repeat;
  box-shadow: 0 -0.438rem 1.875rem -0.875rem rgba(185, 248, 105, 0.75);
  overflow: hidden;
  transition: all 0.25s ease;
  text-decoration: none;
`;

export const StyledCover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  font-size: 0;
  border-radius: 0.938rem;
  width: 30%;
  height: 100%;
  background-image: linear-gradient(180deg, #9f76f7, #359bd2);
  @media (max-width: 768px) {
    display: none;
  }
`;
export const StyledCoverImg = styled.img`
  border-radius: 0.938rem;
  mix-blend-mode: normal;
  height: inherit;
  width: 100%;
  object-fit: cover;
`;
