import { makeAutoObservable, observable } from "mobx";
import { backendLogger } from "../utils/logger";
import { returnAuthConfig } from "../utils/utils";

export class DataStore {
  news = [];
  filteredEventsData = [];
  formats = [];
  eventTypeData = [];
  eventCitiesData = [];
  event = {};
  sizesData = [];
  regionsData = [];
  teamsData = [];
  eventsData = [];

  setSizes = (sizes) => {
    this.sizesData = sizes;
  };

  setEvents = (events) => {
    this.eventsData = events;
  };

  setNews = (news) => {
    this.news = news;
  };

  setType = (eventType) => {
    this.eventTypeData = eventType;
  };

  setEventCities = (eventCities) => {
    this.eventCitiesData = eventCities;
  };

  setTeams = (teams) => {
    this.teamsData = teams;
  };

  cleanFormats = (formats) => {
    this.event = {};
    this.formats = formats;
  };

  setLoadFormats = (formats) => {
    this.formats = formats;
  };

  setFilteredEvents = (filteredEvents) => {
    this.filteredEventsData = filteredEvents;
  };
  setRegions = (regions) => {
    this.regionsData = regions;
  };

  loadEvents = async () => {
    const response = await this.api.get(`/api/event/list`);

    if (response.status === 200 && response.data.values.length) {
      this.setEvents(response.data.values);
    } else if (response.status >= 400) backendLogger(response);
  };

  loadEventsVolunteer = async () => {
    const config = returnAuthConfig();
    const response = await this.api.get(`/api/event/list`, config);

    if (response.status === 200 && response.data.values.length) {
      this.setEvents(response.data.values);
    } else if (response.status >= 400) backendLogger(response);
  };

  loadNews = async () => {
    const response = await this.api.get("/api/news");

    if (response.status === 200) {
      this.setNews(response.data);
    } else if (response.status >= 400) {
      backendLogger(response);
    }
  };
  loadSize = async (data) => {
    const response = await this.api.get(`/api/shop/shirts/${data.city_id}/${data.event_public_id}`);

    if (response.status === 200) this.setSizes(response.data.values);
  };

  loadFormats = async (event_id, city_id, hidden = false, path = "event_format") => {
    const response = await this.api.get(
      `/api/${path}/event/${event_id}/${city_id}${hidden ? "?hidden=true" : ""}`
    );

    if (response.status === 200) {
      this.setLoadFormats(response.data.values);
    } else if (response.status >= 400) {
      backendLogger(response);
    }
  };
  loadFilteredEvents = async (data) => {
    const response = await this.api.post(`/api/event_city/filtered`, data);

    if (response.status === 200) {
      this.setFilteredEvents(response.data.values);
    }
  };

  loadEventType = async () => {
    const response = await this.api.get(`api/event_type/list`);

    if (response.status === 200) this.setType(response.data.values);
  };

  loadEventCities = async () => {
    const response = await this.api.get(`api/event/cities`);

    if (response.status === 200) this.setEventCities(response.data.values);
  };

  loadTeams = async (format_public_id) => {
    const response = await this.api.get(`/api/teams/${format_public_id}`);

    if (response.status === 200) this.setTeams(response.data.values);
    else this.setTeams([]);
  };

  loadDeliveryRegions = async () => {
    const response = await this.api.get(`/api/location/region/list/RU`);
    if (response.status === 200) {
      this.setRegions(response.data.values);
    }
  };

  get newsList() {
    return this.news;
  }
  getNewsByID = (id) => {
    return this.newsList.find((item) => item.public_id === id);
  };
  getNewsWithoutID = (id) => {
    return this.newsList.filter((item) => item.public_id !== id);
  };

  get filteredEvents() {
    return this.filteredEventsData;
  }

  get events() {
    return this.eventsData;
  }

  get eventType() {
    return this.eventTypeData;
  }
  get eventCities() {
    return this.eventCitiesData;
  }
  get teams() {
    return this.teamsData;
  }
  get regions() {
    return this.regionsData;
  }

  get sizes() {
    return this.sizesData;
  }

  constructor(api) {
    this.api = api;

    makeAutoObservable(this, { api: observable.ref });
  }
}
