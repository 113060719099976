import { Layer, Select } from "grommet";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Breadcrumbs, Container, TextInput } from "storybook";
import { screens } from "../../const/const";
import { useMemberRegistrationStore } from "../../hooks/useMemberRegistrationStore";
import { useDataStore } from "../../hooks/useDataStore";
import { setEventsOptions } from "../../mocks/calendarOptions";
import { observer } from "mobx-react";

export const MemberReg = observer(() => {
  const location = useLocation();
  const [isEventSelected, setIsEventSelected] = useState(false);
  const [event, setEvent] = useState(null);
  const [city, setCity] = useState(null);
  const [isSearchNumberTicket, setSearchNumberTicket] = useState(true);
  const [isEditNumber, setIsEditNumber] = useState(false);
  const [ticket, setTicket] = useState(null);

  const { screen } = useMemberRegistrationStore();
  const { events, loadEventsVolunteer } = useDataStore();
  useEffect(() => {
    loadEventsVolunteer();
  }, []);
  console.log(setEventsOptions(events));
  return (
    <div>
      <Container>
        <Breadcrumbs location={location} />
        <section>
          <div>
            <div>
              <h1>{screen === screens.result && "Результаты поиска"}</h1>
              {isEventSelected && event !== null && (
                <>
                  <h2>
                    {`${event.label}, ${city.label}`}{" "}
                    <button onClick={handleEventReset} type="button">
                      Выбрать другое мероприятие
                    </button>
                  </h2>
                </>
              )}
            </div>

            {isEventSelected && (
              <button /* onClick={handleOpenStatPopup} */ type="button">
                <span>Статистика</span>
              </button>
            )}
          </div>

          {isEventSelected ? (
            <>
              {screen !== screens.result && screen !== screens.thanks && (
                <>
                  <div>
                    <label htmlFor="number-tickets">
                      Номер {isSearchNumberTicket ? "билета" : "команды"} участника:
                    </label>
                    <form action="" /* onSubmit={handleFormSubmit} */>
                      <div>
                        <div>
                          <button
                            onClick={() => setSearchNumberTicket(!isSearchNumberTicket)}
                            type="button"
                          >
                            искать по номеру {isSearchNumberTicket ? "команды" : "билета"}
                          </button>
                          <TextInput
                            /*      error={numberError}
                            value={number} */
                            type="number"
                            label=""
                            name="number-tickets"
                            id="number-tickets"
                            /* onChange={handleNumberChange}
                            onFocus={handleNumberFocus} */
                            // disabled={screen === screens.dataMember}
                          />
                        </div>
                        <button
                          type="submit"
                          // disabled={screen === screens.dataMember}
                        >
                          Поиск {isSearchNumberTicket ? "билета" : "команды"}
                        </button>
                      </div>
                      {screen === screens.first && number > 0 && (
                        <button type="reset" /*  onClick={handleNumberReset} */>
                          <span>Очистить поле</span>
                          {/* <TrashIcon /> */}
                        </button>
                      )}
                    </form>
                  </div>

                  <section>
                    {screen === screens.first && <h2>Дополнительные поля</h2>}
                    {screen === screens.dataMember && (
                      <div>
                        <div>
                          <span>Билет {ticket.info.number || "*****"}</span>&ensp;|&ensp;
                          {ticket.team && ticket.team.number && (
                            <>
                              <span>Команда {ticket.team.number || "*****"}</span>&ensp;|&ensp;
                            </>
                          )}
                          <span>Формат {ticket.event_format.title || "Формат"}</span>
                          <button onClick={() => setIsEditNumber(!isEditNumber)} type="button">
                            <span>Редактировать</span>
                          </button>
                        </div>
                        <div>
                          <span>Статус:</span>
                          <div>
                            <span>
                              {ticket.info.registered ? "Данные добавлены" : "Данные отсутствуют"}
                            </span>
                            <span>{ticket.info.insurance ? "Застрахован" : "Нет страховки"}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </section>
                </>
              )}
            </>
          ) : (
            <div>
              <Select
                /*  onChange={({ value: nextValue }) => handleActivityFilter(nextValue, "activity")}
                value={activity} */
                valueKey={{ key: "value", reduce: true }}
                labelKey="label"
                id="activity"
                name="activity"
                options={setEventsOptions(events)}
                placeholder="Выбрать мероприятие"
              />
              {/*  <Select
                disabled={event === null}
                error=""
                prefix="member-select"
                title="Город"
                name="city"
                id="city-t"
                placeholder="Выберите город"
              /> */}

              <button
                onClick={() => setIsEventSelected(true)}
                type="button"
                disabled={city === null}
              >
                Продолжить
              </button>
            </div>
          )}

          {/* { statPopup &&
          <Layer>
          <div >
            <h2 >Статистика</h2>

            <div >
              <button  onClick={() => setFormatsTab(true)} type="button">По форматам</button>
              <span> | </span>
              <button  onClick={() => setFormatsTab(false)} type="button">По товарам</button>
            </div>

            { isFormatsTab ?
              <>
                { Object.keys(statTickets).length > 0 ?
                  <>
                    <div >
                      <p>Всего участников: {statTickets && statTickets.tickets_count}</p>
                      <p>Всего зарегистрировано: {statTickets && statTickets.offline_registered}</p>
                    </div>

                    <table >
                      <thead>
                      <tr>
                        <th>Формат</th>
                        <th>Всего участников</th>
                        <th>Всего зарегистрировано</th>
                      </tr>
                      </thead>
                      <tbody>
                      { (statTickets.cities.length > 0) && searchCity(statTickets.cities, city.value).map((format) => (
                        <tr>
                          <td>{format.title}</td>
                          <td>{format.tickets_count}</td>
                          <td>{format.offline_registered}</td>
                        </tr>
                      )) }
                      </tbody>
                    </table>
                  </>
                  :
                  <p>Нет статистики</p>
                }
              </>
              :
              <>
                <ul>
                  { statProducts.length > 0 && statProducts.map((product) => (
                    <li>{product.title}: {product.count} шт.</li>
                  )) }
                </ul>
              </>
            }

            <button onClick={() => setStatPopup(false)} >
              <span >Закрыть попап</span>
            </button>
          </div>
        </Layer>
      } */}
        </section>
      </Container>
    </div>
  );
});
