import { grommet } from "grommet";
import { deepMerge } from "grommet/utils";
import { NameSpace } from "../const/namespace";

export const theme = deepMerge(grommet, {
  global: {
    breakpoints: {
      [NameSpace.minimobile]: {
        value: 375,
      },
      [NameSpace.mobile]: {
        value: 576,
      },
      [NameSpace.tablet]: {
        value: 1024,
      },
      [NameSpace.desktop1200]: {
        value: 1200,
      },
      [NameSpace.desktop1400]: {
        value: 1400,
      },
      [NameSpace.desktop1900]: {
        value: 3000,
      },
    },

    font: {
      family: "Gotham Pro",
      size: "16px",
      lineHeight: "24px",
      fontStyle: `normal`,
    },

    spacing: "12px",

    colors: {
      brand: `#315FB6`,
      "site-colors-1": `#F8F8F8`,
      "site-colors-2": `#E8EAEC`,
      "site-colors-3": `#EDEFF2`,
      "site-colors-4": `#D0DAE8`,
      "dark-site-color": `#1A1A1A`,
      grey: `#D1D4D8`,
      "dark-blue": `#333F51`,
      black: `#000000`,
      white: `#ffffff`,
      text: {
        light: `#333333`,
        dark: `#ffffff`,
      },
      focus: `transparent`,
      placeholder: `#E0E2EE`,
    },

    input: {
      padding: "0px",
      weight: "400",
    },
  },

  heading: {
    font: {
      family: "Gotham Pro",
      fontWeight: 700,
      fontStyle: `normal`,
      letterSpacing: `1px`,
    },

    level: {
      1: {
        xxlarge: {
          size: `3.5rem`, //58px
          lineHeight: `2.667rem`, //64px
        },
      },
      2: {
        xlarge: {
          size: `3.125rem`, //50px
          lineHeight: `2.417rem`, //58px
        },
      },
      3: {
        medium: {
          size: `2.375rem`, //38px
          lineHeight: `1.917rem`, //46px
        },
      },
      4: {
        small: {
          size: `1.5rem`, //24px
          lineHeight: `1.333rem`, //32px
        },
      },
      5: {
        xsmall: {
          size: `1.125rem`, //18px
          lineHeight: `1.083rem`, //26px
        },
      },
      6: {
        xxsmall: {
          size: `1rem`, //16px
          lineHeight: `1rem`, //24px
        },
      },
    },
  },

  paragraph: {
    font: {
      family: "Gotham Pro",
      fontStyle: `normal`,
      fontWeight: `400`,

      xxsmall: {
        size: `0.625`, // 10px
        height: `0.75rem`, //18px
      },
      xsmall: {
        size: `0.75rem`, //12px
        height: `0.833rem`, //20px
      },
      small: {
        size: `0.875rem`, //14px
        height: `0.917rem`, //22px
      },
      medium: {
        size: `1rem`, //16px
        height: `1rem`, //24px
      },
      large: {
        size: `1.125rem`, //18px
        height: `1.083rem`, //26px
      },
      xlarge: {
        size: `1.5rem`, //24px
        height: `1.333rem`, //32px
      },
    },
  },

  button: {
    border: {
      width: 0,
      radius: `100px`,
    },

    extend: (props) => `
      font-size: 12px;
      line-height: 2;
      letter-spacing: 0.5px;
    `,
  },

  formField: {
    label: {
      color: "black",
      size: "small",
      margin: { bottom: "1rem", top: "0", left: "0", right: "0" },
    },
    border: false,
    margin: "0",
  },

  textInput: {
    extend: () => `
      background: transparent;
      margin: 0 auto;
    `,
  },

  rangeInput: {
    track: {
      color: "black",
      height: "2px",
      extend: () => `border-radius: 10px`,
      lower: {
        color: "dark-site-color",
        opacity: 0.7,
      },
      upper: {
        color: "dark-blue",
        opacity: 0.3,
      },
    },
    thumb: {
      color: "black",
    },
  },

  anchor: {
    pad: 0,
    color: "brand",
    active: {
      textDecoration: "underline",
    },
    fontWeight: 400,
  },

  tab: {
    color: "grey",
    margin: `0 16px`,
    pad: {
      bottom: `16px`,
      horizontal: "small",
    },
    background: "transparent",
    border: {
      width: "2px",
      side: `bottom`,
      size: `small`,
      color: {
        dark: ``,
        light: `grey`,
      },
      active: {
        color: {
          light: `black`,
        },
      },
      hover: {
        color: {
          light: `black`,
        },
      },
    },
    hover: {
      color: "black",
    },
    active: {
      background: "transparent",
      color: "black",
    },
  },

  menu: {
    icons: {
      color: "black",
    },
    background: "white",
  },
});
