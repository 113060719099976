import { makeAutoObservable, observable } from "mobx";
import { MESSAGES } from "../const/messages";
import { backendLogger } from "../utils/logger";
import { returnAuthConfig, updateLocalStorage } from "../utils/utils";

export class ProfileStore {
  passwordMessageData = "";
  activity = [];
  dataMessage = "";
  status = false;
  extraContactStatus = false;
  changeEmailStatusData = "";
  viewCodeField = false;
  changePhoneStatusData = "";
  changeCodeStatusData = "";
  passwordErrorData = "";

  setPasswordMessage = (passwordMessage) => {
    this.passwordMessageData = passwordMessage;
  };

  setLoadActivity = (activity) => {
    this.activity = activity;
  };

  setStatus = (status) => {
    this.status = status;
  };

  setPasswordError = (passwordError) => {
    this.passwordErrorData = passwordError;
  };

  setMessage = (dataMessage) => {
    this.dataMessage = dataMessage;
  };

  setExtraContactStatus = (extraContactStatus) => {
    this.extraContactStatus = extraContactStatus;
  };

  setChangeEmailStatus = (changeEmailStatus) => {
    this.changeEmailStatusData = changeEmailStatus;
  };

  setViewCodeField = (viewCodeField) => {
    this.viewCodeField = viewCodeField;
  };

  setChangePhoneStatus = (changePhoneStatus) => {
    this.changePhoneStatusData = changePhoneStatus;
  };
  setChangeCodeStatus = (changeCodeStatus) => {
    this.changeCodeStatusData = changeCodeStatus;
  };

  loadActivity = async (data = "") => {
    const config = returnAuthConfig();
    const response = await this.api.post("/api/auth/history", data, config);

    if (response.status === 200) {
      this.setLoadActivity(response.data.values.reverse());
    } else if (response.status >= 400) {
      backendLogger(response);
    }
  };

  setNewData = async (data) => {
    const config = returnAuthConfig();
    const response = await this.api.put("/api/user", data, config);

    if (response.status === 200) {
      updateLocalStorage("user", "update", data);

      if (data.name || data.birth_date || data.last_name || data.gender) {
        this.setStatus(response.data.message);
        this.setMessage("Данные успешно обновлены");
      }
      if (data.extra_contact) {
        this.setExtraContactStatus(response.data.message);
      }
    } else {
      backendLogger(response);
      // this.setExtraContactStatus(response.status)
    }
  };

  setNewMail = async (email) => {
    const config = returnAuthConfig();
    const response = await this.api.post("/api/user/change/email", email, config);

    if (response.status === 200) {
      this.setChangeEmailStatus(MESSAGES.EMAIL_NOT_CONFIRM);
    } else {
      this.setChangeEmailStatus(response.data.message);
    }
  };

  setNewPhone = async (data) => {
    const config = returnAuthConfig();
    const response = await this.api.post("/api/user/new/phone", data, config);

    if (response.status === 200) {
      this.setViewCodeField(false);
      updateLocalStorage("user", "update", { phone: data.phone });
      this.setChangePhoneStatus(MESSAGES.PHONE_CONFIRM); // view update phone text
    } else if (response.status >= 400) {
      this.setChangeCodeStatus(response.data.message);
    }
  };

  sendNewPhoneCode = async (phone) => {
    const config = returnAuthConfig();
    const response = await this.api.post("/api/user/phone/code", phone, config);

    if (response.status === 200) {
      // view code field
      this.setViewCodeField(response.data.message);
      this.setChangeCodeStatus(MESSAGES.PHONE_NOT_CONFIRM);
    } else if (response.status >= 400) {
      this.setChangePhoneStatus(response.data.message);
    }
  };

  setNewPassword = async (data) => {
    const config = returnAuthConfig();
    const response = await this.api.put("/api/user/password", data, config);

    if (response.status === 200) {
      this.setPasswordMessage(response.data.message);
      this.setPasswordError("");
    } else if (response.status >= 400) {
      this.setPasswordError(response.data.message);
      backendLogger(response);
    }
  };

  get activities() {
    return this.activity;
  }
  get message() {
    return this.dataMessage;
  }
  get passwordMessage() {
    return this.passwordMessageData;
  }
  get changeEmailStatus() {
    return this.changeEmailStatusData;
  }
  get viewCodeParam() {
    return this.viewCodeField;
  }
  get changePhoneStatus() {
    return this.changePhoneStatusData;
  }
  get changeCodeStatus() {
    return this.changeCodeStatusData;
  }

  get passwordError() {
    return this.passwordErrorData;
  }

  constructor(api) {
    this.api = api;
    this.loadActivity();

    makeAutoObservable(this, {
      api: observable.ref,
    });
  }
}
