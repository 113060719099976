import { Container } from "storybook";
import React, { useContext, useState } from "react";
import { Box, ResponsiveContext } from "grommet";
import { isMobileTabletResponsive } from "../../utils/utils";
import {
  Section,
  UserSection,
  QualificationSection,
  OrderSection,
  CreateEventSection,
  ChangeEventSection,
  EventsSection,
  ActivitySection,
} from "storybook";
import { observer } from "mobx-react";
import { useProfileStore } from "../../hooks/useProfileStore";
import { useUserStore } from "../../hooks/useUserStore";

const Cabinet = observer(() => {
  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);
  const [section, setSection] = useState("UserSection");

  const { logout } = useUserStore();
  const {
    activities,
    changeEmailStatus,
    setNewMail,
    passwordMessage,
    setNewPassword,
    passwordErrorData,
    viewCodeParam,
    changePhoneStatus,
    setViewCodeField,
    setChangePhoneStatus,
    setNewPhone,
    sendNewPhoneCode,
    changeCodeStatusData,
    setChangeCodeStatus,
    setNewData,
    message,
    setStatus,
    setMessage,
  } = useProfileStore();

  return (
    <>
      <Container>
        <Box
          flex
          direction={isMobileWidth ? "column" : "row"}
          justify="between"
          style={{ position: "relative" }}
        >
          <Box width={isMobileWidth ? "100%" : "25%"} margin={{ bottom: isMobileWidth && "2rem" }}>
            <Section
              setSection={setSection}
              isMobileWidth={isMobileWidth}
              section={section}
              logout={logout}
            />
          </Box>

          <Box
            width={isMobileWidth ? "100%" : "73%"}
            margin={{ bottom: isMobileWidth ? "3rem" : "0" }}
          >
            {section === "UserSection" && (
              <UserSection
                message={message}
                setStatus={setStatus}
                setMessage={setMessage}
                setNewData={setNewData}
                viewCodeParam={viewCodeParam}
                setChangeCodeStatus={setChangeCodeStatus}
                changeCodeStatus={changeCodeStatusData}
                sendNewPhoneCode={sendNewPhoneCode}
                setNewPhone={setNewPhone}
                changePhoneStatus={changePhoneStatus}
                setViewCodeField={setViewCodeField}
                setChangePhoneStatus={setChangePhoneStatus}
                passwordMessage={passwordMessage}
                setNewPassword={setNewPassword}
                passwordError={passwordErrorData}
                changeEmailStatus={changeEmailStatus}
                setNewMail={setNewMail}
              />
            )}
            {section === "QualificationSection" && <QualificationSection />}
            {section === "OrderSection" && <OrderSection />}
            {section === "CreateEventSection" && <CreateEventSection />}
            {section === "ChangeEventSection" && <ChangeEventSection />}
            {section === "EventsSection" && <EventsSection />}
            {section === "ActivitySection" && <ActivitySection activities={activities} />}
          </Box>
        </Box>
      </Container>
    </>
  );
});

export default Cabinet;
