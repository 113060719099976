import Image from "../../images/banners/federation-banner.jpg";
import { AntiDopingDocs, Breadcrumbs, ButtonGroup, Banner, Container } from "storybook";
import { observer } from "mobx-react";
import { useAntiDopingStore } from "../../hooks/useAntiDopingStore";
import { useLocation } from "react-router-dom";

const AntiDoping = observer(() => {
  const { antiDopingDocs } = useAntiDopingStore();
  const location = useLocation();

  return (
    <>
      <Banner cover={Image} />
      <Container>
        <Breadcrumbs location={location} />
        <ButtonGroup />
        <AntiDopingDocs data={antiDopingDocs} />
      </Container>
    </>
  );
});

export default AntiDoping;
