import Image from "../../images/banners/department-banner.jpg";
import { Breadcrumbs, Banner, Container, CustomTable } from "storybook";
import { departments } from "../../mocks/departments";
import { useLocation } from "react-router-dom";

const headList = [
  {
    name: "id",
    label: "№",
  },
  {
    name: "name",
    label: "Название",
  },
  {
    name: "phone",
    label: "Телефон",
  },
  {
    name: "email",
    label: "Электронная почта",
  },
  {
    name: "link",
    label: "Сайт",
  },
];

const Departments = () => {
  const location = useLocation();

  return (
    <>
      <Banner cover={Image} />
      <Container>
        <Breadcrumbs location={location} />
        <CustomTable headList={headList} content={departments} />
      </Container>
    </>
  );
};

export default Departments;
