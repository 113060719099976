export const setGtmOptions = (page) => {
  return {
    dataLayer: {
      userId: localStorage.userObj ? JSON.parse(localStorage.userObj).public_id : null,
      userProject: "heroleague",
      page: page,
    },
    dataLayerName: "PageDataLayer",
  };
};
