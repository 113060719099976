import { getFormatTime, times } from "../../utils/date";
import {
  StyledContentWrapper,
  StyledCover,
  StyledCoverImg,
  StyledImage,
  StyledInfoBlock,
  StyledInfoItem,
  StyledInfoLabel,
  StyledList,
  StyledListItem,
  StyledListWrap,
  StyledMain,
  StyledParagraph,
  StyledTitle,
  StyledWrapper,
} from "./styles";

export const TicketCard = ({ ticket }) => {
  if (!ticket) return <div>Загрузка...</div>;

  const { event, event_format } = ticket;

  return (
    <StyledWrapper>
      <StyledContentWrapper>
        <StyledMain>
          <StyledImage src={event?.event_type?.logo} width="69" height="34" alt="Логотип билета" />
          <StyledParagraph>{event_format?.description}</StyledParagraph>
        </StyledMain>

        <StyledListWrap>
          <StyledTitle>Условия участия</StyledTitle>
          <StyledList>
            {event_format?.requirements.map((req) => (
              <StyledListItem key={req}>{req}</StyledListItem>
            ))}
          </StyledList>
        </StyledListWrap>

        <StyledInfoBlock>
          <StyledInfoItem>
            <StyledInfoLabel>Формат:</StyledInfoLabel>
            {ticket.event_format?.title}
          </StyledInfoItem>
          <StyledInfoItem>
            <StyledInfoLabel>Дата и время старта:</StyledInfoLabel> {getFormatTime(ticket)}
          </StyledInfoItem>
          <StyledInfoItem>
            <StyledInfoLabel>Город:</StyledInfoLabel> {ticket.city.name_ru}
          </StyledInfoItem>
          <StyledInfoItem>
            <StyledInfoLabel>Номер заказа:</StyledInfoLabel>
            {ticket.order_public_id}
          </StyledInfoItem>
          <StyledInfoItem>
            <StyledInfoLabel>Страховка:</StyledInfoLabel>
            {ticket?.info?.insurance ? `Есть` : `Нет`}
          </StyledInfoItem>
          {ticket.team && (
            <StyledInfoItem>
              <StyledInfoLabel>Команда:</StyledInfoLabel>№&nbsp;{ticket.team.number}
              {`${ticket.info.inside_number ? "-" + ticket.info.inside_number : ""}`}
            </StyledInfoItem>
          )}
          <StyledInfoItem>
            <StyledInfoLabel>Номер билета:</StyledInfoLabel>
            №&nbsp;{ticket?.info?.registered ? ticket?.info?.number : `****`}
          </StyledInfoItem>
        </StyledInfoBlock>

        <StyledCover>
          <StyledCoverImg
            src={event?.banners?.landing_page?.desktop_picture}
            alt="Фото участников"
          />
        </StyledCover>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};
