import { action, makeAutoObservable, observable } from "mobx";
import { AUTH, NO_AUTH } from "../const/user";

export class UserStore {
  user = {};
  authStatusData = NO_AUTH;
  authErrorData = "";
  isSendedMailData = false;
  isConfirmEmailErrorMessage = "";

  setRegistration = (user) => {
    this.user = user;
  };

  setAuthStatus = (authStatus) => {
    this.authStatusData = authStatus;
  };

  setSendMail = (isSendedMail) => {
    this.isSendedMailData = isSendedMail;
  };

  setConfirmEmailError = (isConfirmEmailError) => {
    this.isConfirmEmailErrorMessage = isConfirmEmailError;
  };

  setAuthError = (authError) => {
    this.authErrorData = authError;
  };
  logout = () => {
    this.setAuthStatus(NO_AUTH);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  };

  checkAuth = () => {
    const token = localStorage.token;

    if (token) {
      this.setAuthStatus(AUTH);
    } else {
      this.setAuthStatus(NO_AUTH);
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
  };

  registration = async (userRegistrationObject) => {
    const response = await this.api.post("/api/user", userRegistrationObject);

    if (response.status === 200) {
      this.setSendMail(true);
    } else if (response.status >= 400) {
      this.setAuthError(response.data.message);
    }
  };

  login = async (authData) => {
    const response = await this.api.put("/api/auth", authData);

    if (response.status === 200) {
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("token", response.data.token);
      this.setAuthStatus(AUTH);
    } else if (response.status >= 400) {
      this.setAuthError(response.data.message);

      if (response.status === 418) {
        this.setConfirmEmailError(response.data.message);
      }
    }
  };

  get authStatus() {
    return this.authStatusData;
  }
  get authError() {
    return this.authErrorData;
  }
  get isSendedMail() {
    return this.isSendedMailData;
  }
  get isConfirmEmailError() {
    return this.isConfirmEmailErrorMessage;
  }

  constructor(api) {
    this.api = api;

    makeAutoObservable(this, { api: observable.ref, setAuthStatus: action });
  }
}
