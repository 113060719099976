import { useEffect } from "react";
import { GlobalStyledModal, StyledModal, StyledModalClose, StyledModalWrapper } from "./styles";
import cross from "../../images/svg/cross.svg";

const Modal = ({ children, className, handleModalState }) => {
  useEffect(() => {
    const body = document.body;

    body.style.overflow = "hidden";

    const modal = document.getElementById("modal");

    return () => (body.style.overflow = "hidden auto");
  }, []);

  const closeHandler = () => {
    setTimeout(() => {
      handleModalState();
    }, 300);

    const modal = document.getElementById("modal");

    modal.classList.add("modal-wrapper-ready");
    modal.firstChild.classList.add("modal-ready");
  };

  return (
    <>
      <GlobalStyledModal />
      <StyledModalWrapper id="modal">
        <StyledModal>
          <StyledModalClose onClick={closeHandler}>
            <img src={cross} alt="close" />
          </StyledModalClose>
          {children}
        </StyledModal>
      </StyledModalWrapper>
    </>
  );
};

export default Modal;
