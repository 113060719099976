import Image from "../../images/banners/management-banner.jpg";
import { Breadcrumbs, Container, Banner, CardWithImage } from "storybook";
import { useLocation } from "react-router-dom";

const Management = () => {
  const location = useLocation();

  return (
    <>
      <Banner cover={Image} style={`img {object-fit: top}`} />
      <Container>
        <Breadcrumbs location={location} />
        <CardWithImage />
      </Container>
    </>
  );
};

export default Management;
