import styled, { css } from "styled-components";

export const StyledLinkTickets = styled.a`
  position: relative;
  width: 14.3125rem;
  padding: 0.5rem 2rem;
  border: 1px solid black;
  border-radius: 0.625rem;
  text-decoration: none;
  text-align: center;
  color: white;
  background-color: black;
`;

export const StyledLinkSettings = styled.a`
  position: relative;
  width: 14.3125rem;
  padding: 0.5rem 2rem;
  border: 1px solid black;
  border-radius: 0.625rem;
  text-decoration: none;
  text-align: center;
  color: black;
  background-color: white;
`;

export const StyledWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3.875rem;
`;
export const StyledWrapButtons = styled.div`
  display: flex;
  gap: 3.5rem;
  width: 100%;
`;
export const StyledUnRegEvents = styled.section`
  margin-bottom: 5rem;
`;
export const StyledRegEvents = styled.section`
  margin-bottom: 5rem;

  & + ${StyledUnRegEvents} {
    padding-top: 3.5rem;
    border-top: 1px solid #151515;
  }
`;

export const StyledTitle = styled.h3`
  font-size: 1rem;
  font-weight: 400;
  color: #5f5e5e;
  margin-bottom: 1.188rem;
`;

export const StyledTitleLink = styled(StyledTitle)`
  ${(props) =>
    props.click
      ? ""
      : css`
          text-decoration: underline;
          cursor: pointer;
        `}
`;

export const StyledList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4.125rem 1.5rem;

  @media (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

export const StyledTransferredEvents = styled.section`
  padding-top: 3.5rem;
  border-top: 1px solid #151515;
  margin-bottom: 4rem;
`;

export const StyledNotExistBlock = styled.div`
  margin-bottom: 7.5rem;
`;

export const StyledNotExistTitle = styled.h3`
  text-transform: uppercase;
  font-size: 2.25rem;

  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
  line-height: 1.222;
  font-weight: 900;
  text-transform: unset;
  margin-bottom: 2.25rem;
`;

export const StyledNotExistLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  max-width: 17.25rem;
  width: 100%;
  font-size: 0.75rem;
  box-shadow: 0 -0.438rem 1.875rem -0.875rem #982eeb;

  color: white;
  border: none;
  cursor: pointer;

  font-size: 0.75rem;
  line-height: 1.166;
  width: 85%;
  border-radius: 0.3125rem;

  max-width: 15rem;
  min-height: 2.5rem;
  border-radius: 1.875rem;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  box-shadow: 0 -0.438rem 1.875rem -0.875rem #359ad2;
  overflow: hidden;
  transition: all 0.25s ease;

  font-size: 0.75rem;
  max-width: 17.25rem;
  width: 100%;

  background: linear-gradient(267.53deg, #982eeb -0.27%, #359ad2 100%);
`;
