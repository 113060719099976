import { makeAutoObservable, observable } from "mobx";
import { screens } from "../const/const";

export class MemberRegistrationStore {
  screenData = screens.first;

  get screen() {
    return this.screenData;
  }

  constructor(api) {
    this.api = api;

    makeAutoObservable(this, { api: observable.ref });
  }
}
