import { Box, Heading } from "grommet";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Container, ExtraInfo, NewsMainSection, Banner } from "storybook";
import styled from "styled-components";
import { app_api } from "../../api";
import HomeSlider from "../../components/HomeSlider/index";
import { useDataStore } from "../../hooks/useDataStore";
import { useEffect } from "react";
import Image from "../../images/banners/Home-banner.png";

const StyledHeading = styled(Heading)`
  margin-bottom: 2.5rem;
  text-transform: uppercase;
  color: #e21a25;
  line-height: 46px;
`;

const Home = observer(() => {
  const { newsList, loadNews } = useDataStore();

  useEffect(() => {
    if (!newsList.length) loadNews();
  }, []);

  return (
    <>
      {/* <HomeSlider /> */}
      <Banner cover={Image} bottom={`5%`} maxHeight={`500px`} minHeight={`100px`} />
      <Container>
        <Box flex direction="column">
          <Box flex direction="column" margin={{ bottom: "6rem" }}>
            <StyledHeading level={3} fill>
              Новости Триатлона Москвы
            </StyledHeading>
            <NewsMainSection app_api={app_api} as={Link} newsList={newsList} />
          </Box>

          <Box flex direction="column" margin={{ bottom: "6rem" }}>
            <StyledHeading level={3} fill>
              СПОРТСМЕНЫ СБОРНОЙ КОМАНДЫ МОСКВЫ И КАЛЕНДАРЬ МФТ
            </StyledHeading>
            <ExtraInfo as={Link} />
          </Box>
        </Box>
      </Container>
    </>
  );
});

export default Home;
