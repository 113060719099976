import styled from "styled-components";

export const StyledWrapper = styled.section`
  position: relative;
`;
export const StyledContentWrapper = styled.div`
  padding: 1.563rem;
  display: flex;
  align-items: stretch;
  align-content: space-between;
  flex-wrap: wrap;
  width: 49.063rem;
  min-height: 16.75rem;
  background-color: #f8f8f8;
  border-radius: 0.938rem;
`;

export const StyledMain = styled.div`
  width: 34.688rem;
`;
export const StyledImage = styled.img`
  margin-bottom: 2.188rem;
`;
export const StyledParagraph = styled.p`
  max-width: 26.813rem;
  width: 100%;
  font-size: 0.625rem;
  word-break: break-all;
`;
export const StyledListWrap = styled.div`
  margin-bottom: 0.75rem;
  padding-top: 0.813rem;
  padding-left: 2.125rem;
  width: 10.5rem;
  border-left: 1px solid grey;
`;
export const StyledTitle = styled.h3`
  margin-bottom: 0.8rem;
  font-size: 0.75rem;
`;

export const StyledList = styled.ul`
  padding-left: 2.25rem;
  display: flex;
  flex-wrap: wrap;
`;
export const StyledListItem = styled.li`
  font-size: 0.625rem;
  list-style: disc;
  line-height: 1.2;

  &:not(:last-child) {
    margin-bottom: 0.563rem;
  }

  &::marker {
    color: #fe4f4f;
  }
`;

export const StyledInfoBlock = styled.div`
  padding-top: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  border-top: 1px solid grey;
`;

export const StyledInfoItem = styled.p`
  margin-right: 2.125rem;
  font-size: 0.675rem;
  font-weight: 700;
  line-height: 1.4;
`;

export const StyledInfoLabel = styled.span`
  display: block;
  width: 100%;
  font-size: 0.625rem;
  font-weight: 400;
`;
export const StyledCover = styled.div`
  position: absolute;
  top: 0;
  right: -25px;
  display: flex;
  justify-content: flex-end;
  font-size: 0;
  border-radius: 0.938rem;
  width: 40%;
  height: 100%;
  background-image: linear-gradient(180deg, #9f76f7, #359bd2);
  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledCoverImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: right;
  border-radius: inherit;
  mix-blend-mode: normal;
`;
