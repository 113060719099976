import { Routes, Route, Navigate } from "react-router-dom";
import { Box, Grommet } from "grommet";
import React from "react";
import TagManager from "react-gtm-module";
import Loader from "react-js-loader";
import TriathlonHistory from "./pages/Article/TriathlonHistory";
import CongratulationsPage from "./pages/CongratulationsPage";
import HowToStart from "./pages/Article/HowToStart";
import CoachingStaff from "./pages/CoachingStaff";
import Departments from "./pages/Departments";
import Federation from "./pages/Federation";
import Management from "./pages/Management";
import MediaPhoto from "./pages/MediaPhoto";
import MediaVideo from "./pages/MediaVideo";
import Documents from "./pages/Documents";
import { Header, Container, Footer, MainLogo } from "storybook";
import Calendar from "./pages/Calendar";
import Contacts from "./pages/Contacts";
import MainCast from "./pages/MainCast";
import Results from "./pages/Results";
import Home from "./pages/Home";
import News from "./pages/News";
import { NewsContent } from "storybook";
import { privateRoutes, routes } from "./const/routes";
import { theme } from "./themes";
import Education from "./pages/Education";
import MaintenanceErrorPage from "./pages/Error/MaintenanceErrorPage";
import AntiDoping from "./pages/AntiDoping";
import { Basket } from "./pages/Basket";
import { Events } from "./pages/Events";
import { useUserStore } from "./hooks/useUserStore";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { app_api } from "./api";
import { useDataStore } from "./hooks/useDataStore";
import renderHTML from "react-render-html";
import { setGtmOptions } from "./utils/gtm";
import { useBasketStore } from "./hooks/useBasketStore";
import { MyEvents } from "./pages/MyEvents";
import { Ticket } from "./pages/Ticket/Ticket";
import { MemberReg } from "./pages/MemberReg";

const App = observer(() => {
  const { authStatus, checkAuth } = useUserStore();
  const { newsList, loadNews } = useDataStore();
  const { goods } = useBasketStore();
  checkAuth();

  return (
    <Grommet theme={theme}>
      <Header goods={goods} as={Link} />

      <Box>
        <Routes>
          {privateRoutes.map((route) => {
            return (
              <Route
                key={route.id}
                path={route.path}
                element={
                  authStatus === route.status ? route.element : <Navigate to={route.pathRedirect} />
                }
              />
            );
          })}
          <Route path={`${routes.myEvents.path}/:id`} element={<Ticket />} />
          <Route path={routes.myEvents.path} element={<MyEvents />} />
          <Route path={routes.main.path} element={<Home />} />
          <Route path={routes.news.path} element={<News />} />
          <Route path={routes.basket.path} element={<Basket />} />
          <Route path={routes.events.path} element={<Events />} />
          <Route path={routes.maintenanceError.path} element={<MaintenanceErrorPage />} />
          <Route
            path={`${routes.news.path}/:id`}
            element={
              <NewsContent
                api={app_api}
                loader={<Loader type="spinner-default" bgColor={`#315FB6`} />}
                renderHTML={renderHTML}
                tagManager={() => TagManager.dataLayer(setGtmOptions(`oneNews`))}
                newsList={newsList}
                loadNews={loadNews}
              />
            }
          />

          <Route path={routes.howToStart.path} element={<HowToStart />} />
          <Route path={routes.triathlonHistory.path} element={<TriathlonHistory />} />

          <Route path={routes.federation.path} element={<Federation />} />
          <Route path={routes.management.path} element={<Management />} />
          <Route path={routes.accreditation.path} element={<Documents />} />
          {/*<Route  path={routes.documents.path} element={<Documents/>} />*/}
          <Route path={routes.contacts.path} element={<Contacts />} />

          <Route path={routes.triathlonDepartments.path} element={<Departments />} />

          <Route path={routes.mainCast.path} element={<MainCast />} />
          <Route path={routes.coachingStaff.path} element={<CoachingStaff />} />
          <Route path={routes.memberReg.path} element={<MemberReg />} />

          <Route path={routes.mediaPhoto.path} element={<MediaPhoto />} />
          <Route path={routes.mediaVideo.path} element={<MediaVideo />} />

          <Route path={routes.calendar.path} element={<Calendar />} />
          <Route path={routes.results.path} element={<Results />} />
          <Route path={routes.antiDoping.path} element={<AntiDoping />} />

          <Route path={routes.congratulationsPage.path} element={<CongratulationsPage />} />
          <Route path={routes.education.path} element={<Education />} />

          <Route path="*" element={<Navigate to={routes.maintenanceError.path} />} />
        </Routes>
      </Box>
      <Container>
        <Footer logo={<MainLogo as={Link} />} />
      </Container>
    </Grommet>
  );
});

export default App;
