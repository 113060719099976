import Image from "../../images/banners/team-banner.jpg";
import { Breadcrumbs, Banner, Container, Table } from "storybook";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import Modal from "../../components/Modal";
import { useState, useEffect } from "react";
import {
  StyledPersonModalContent,
  StyledPersonModalImage,
  StyledPersonModalImageWrapper,
  StyledPersonModalImageWrapperDefault,
  StyledPersonModalInfo,
  StyledPersonModalName,
} from "./styles";

import person from "../../images/athletes/default.png";

import { useMembersStore } from "../../hooks/useMembersStore";
import { app_api } from "../../api";
import { checkAndSetImgUrl } from "../../utils/utils";

const headList = [
  {
    name: "picture",
    label: "",
  },
  {
    name: "teams_squad",
    label: "Состав (основной, юниорский ст. юноши)",
  },
  {
    name: "name",
    label: "Ф. И. О.",
  },
  {
    name: "birthday",
    label: "Дата рождения",
  },
  {
    name: "title",
    label: "Спортивное звание, разряд",
  },
];

const MainCast = observer(() => {
  const location = useLocation();

  const [modalState, setModalState] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);

  const handleModalState = () => {
    setModalState((state) => !state);
  };

  const handleCurrentItem = (id, groupName) => {
    const item = members.find((member) => member._id == id);
    const updItem = { ...item };
    updItem.group_public_id = groupName;
    setCurrentItem(updItem);
  };

  const { members, loadAthletes } = useMembersStore();

  const { membersGroup, loadMembersGroup } = useMembersStore();

  const [membersObject, setMembersObject] = useState({});

  const CreateMembersObject = (groups, members) => {
    let resultObject = { ...membersObject };

    members.map((member) => {
      const group = groups.find((group) => group.public_id == member.group_public_id);
      const array = resultObject[group.name] ? [...resultObject[group.name], member] : [member];

      resultObject = { ...resultObject, [group.name]: array };
    });

    return resultObject;
  };

  useEffect(() => {
    members?.length === 0 && loadAthletes();
    membersGroup?.length === 0 && loadMembersGroup();

    setMembersObject(CreateMembersObject(membersGroup, members));
  }, []);

  useEffect(() => {
    if (members.length > 0 && membersGroup.length > 0)
      setMembersObject(CreateMembersObject(membersGroup, members));
  }, [members, membersGroup]);

  return (
    <>
      {modalState && (
        <Modal handleModalState={handleModalState}>
          <StyledPersonModalContent>
            {!!currentItem.picture ? (
              <StyledPersonModalImageWrapper>
                <StyledPersonModalImage
                  src={`${app_api}${currentItem.picture}`}
                  onError={checkAndSetImgUrl}
                  alt="image"
                />
              </StyledPersonModalImageWrapper>
            ) : (
              <StyledPersonModalImageWrapperDefault>
                <StyledPersonModalImage src={person} />
              </StyledPersonModalImageWrapperDefault>
            )}
            <StyledPersonModalInfo>
              <StyledPersonModalName>{currentItem?.name}</StyledPersonModalName>

              <ul>
                <li>
                  <span>Состав:</span> {currentItem?.group_public_id}
                </li>
                <li>
                  <span>День рождения:</span>{" "}
                  {new Date(currentItem?.birthday * 1000).toLocaleDateString()}
                </li>
                <li>
                  <span>Спортивное звание, разряд:</span> {currentItem?.title}
                </li>
              </ul>
            </StyledPersonModalInfo>
          </StyledPersonModalContent>
        </Modal>
      )}
      <Banner cover={Image} />
      <Container>
        <Breadcrumbs location={location} />
        <Table
          headList={headList}
          imageWidth="50px"
          app_api={app_api}
          members={membersObject}
          handleModalState={handleModalState}
          handleCurrentItem={handleCurrentItem}
        />
      </Container>
    </>
  );
});

export default MainCast;
