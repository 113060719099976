import { TextInput } from "storybook";
import React, { useState, useEffect } from "react";
import { Goods } from "./Goods";
import {
  StyledActivity,
  StyledAside,
  StyledAsideSubTitle,
  StyledAsideTitle,
  StyledAsideTitleWrap,
  StyledButton,
  StyledCertificate,
  StyledCost,
  StyledLink,
  StyledList,
  StyledListItem,
  StyledListItemWrap,
  StyledParagraph,
  StyledSaleWrap,
  StyledSendBlock,
  StyledText,
  StyledTotalCost,
  StyledTotalWrap,
  StyledWarningMessage,
  StyledWrapper,
} from "./styles";
import { makeValidationSchema } from "./makeValidationSchema";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { observer } from "mobx-react";
import { saleCounter } from "../../utils/goods";
import { StyledDiscount, StyledPopupWrapper } from "./Goods/styles";
import { checkActualTicketsCount, getPaymentObject } from "../../utils/basket";
import { Layer } from "grommet";

export const BasketComponent = observer(
  ({
    goods,
    removeShop,
    updateGoods,
    giftDiscount = { value: 0 },
    as,
    errorPayment,
    promoCodeError,
    setPromoCodeError,
    sendPromoCode,
    promoCodes,
    removePromoCode,
    ticketFormatsInBasket,
    checkTicketsBeforePaymentByFormatId,
    payment,
    setError,
  }) => {
    const validateForm = makeValidationSchema();
    const user = localStorage.user && JSON.parse(localStorage.user);

    const {
      handleSubmit,
      control,
      formState: { errors },
      clearErrors,
      setValue,
    } = useForm({
      resolver: yupResolver(validateForm),
      shouldFocusError: false,
      defaultValues: { email: user?.email || "" },
    });

    const [totalDiscount, setTotalDiscount] = useState(0);
    const [isOpenPopup, setIsOpenPopup] = useState(true);

    const onSubmit = ({ email }) => {
      if (checkActualTicketsCount(ticketFormatsInBasket, goods)) {
        const sendGoods = getPaymentObject(goods, promoCodes, email);
        payment(sendGoods);
      }
    };

    useEffect(() => {
      return () => {
        setPromoCodeError({ message: "", id: "" });
      };
    }, []);

    useEffect(() => {
      // счетчик скидки
      const discount = promoCodes
        .map((sale) => {
          if (sale.discount_form === "percent") {
            const oneGoods = goods.find((el) => el.public_id === sale.public_id);
            return saleCounter(oneGoods, sale);
          } else {
            return sale.discount && sale.discount > 0 ? sale.discount : 0;
          }
        })
        .reduce((a, b) => a + b, 0)
        .toFixed(0);

      setTotalDiscount(+discount);
    }, [promoCodes, goods]);

    const handleButtonClose = () => {
      setError({ message: "" });
      setIsOpenPopup(false);
    };

    useEffect(() => {
      if (errorPayment.message) {
        setIsOpenPopup(true);
      }
    }, [errorPayment]);

    return (
      <StyledWrapper>
        {goods.length ? (
          <ul>
            {goods.map((item, i) => (
              <Goods
                checkTicketsBeforePaymentByFormatId={checkTicketsBeforePaymentByFormatId}
                removePromoCode={removePromoCode}
                promoCodes={promoCodes}
                sendPromoCode={sendPromoCode}
                promoCodeError={promoCodeError}
                removeShop={removeShop}
                updateGoods={updateGoods}
                key={`${i}${item.public_id}`}
                item={item}
              />
            ))}
          </ul>
        ) : (
          <h2>Ваша корзина пуста</h2>
        )}
        {goods.length ? (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <StyledAside>
                <StyledAsideTitleWrap>
                  <StyledAsideTitle>Ваша корзина</StyledAsideTitle>
                  <StyledAsideSubTitle>
                    Доступные способы оплаты можно выбрать при оформлении заказа
                  </StyledAsideSubTitle>

                  <StyledWarningMessage>
                    <StyledCertificate>
                      На все наши мероприятия необходимо иметь при себе медицинскую справку
                    </StyledCertificate>
                  </StyledWarningMessage>
                </StyledAsideTitleWrap>

                <StyledList>
                  {goods.filter((el) => el.selector === "ticket").length ? (
                    <StyledListItem>
                      <StyledListItemWrap>
                        <h3>Мероприятия</h3>
                        <StyledText>
                          <span>{goods.filter((el) => el.selector === "ticket").length}</span>
                          &nbsp;шт.
                        </StyledText>
                      </StyledListItemWrap>
                      <ul>
                        {goods.map((el, i) =>
                          el.selector === "ticket" ? (
                            <StyledActivity key={`${el._id}${i}`}>
                              <span>{el.size ? `${el.title} ${el.size}` : el.title}</span>
                              <StyledText>{el.totalCost} &#8381;</StyledText>
                            </StyledActivity>
                          ) : null
                        )}
                      </ul>
                    </StyledListItem>
                  ) : null}

                  {goods.filter((el) => el.selector === "certificate").length ? (
                    <li>
                      <div>
                        <h3>Сертификаты</h3>
                        <span>
                          <span>{goods.filter((el) => el.selector === "certificate").length}</span>
                          шт.
                        </span>
                      </div>
                      <ul>
                        {goods.map((el, i) =>
                          el.selector === "certificate" ? (
                            <li key={`${el._id}${i}`}>
                              <span>{el.size ? `${el.title} ${el.size}` : el.title}</span>
                              <span>{el.totalCost} &#8381;</span>
                            </li>
                          ) : null
                        )}
                      </ul>
                    </li>
                  ) : null}
                </StyledList>

                <StyledTotalWrap>
                  {totalDiscount !== 0 && (
                    <StyledSaleWrap>
                      <StyledDiscount>Скидка: </StyledDiscount>
                      <StyledDiscount>{totalDiscount} &#8381;</StyledDiscount>
                    </StyledSaleWrap>
                  )}
                  {giftDiscount.value !== 0 && (
                    <StyledSaleWrap>
                      <span>Подарочная скидка ({giftDiscount.volume}):</span>
                      <span>{giftDiscount.value.toLocaleString()} &#8381;</span>
                    </StyledSaleWrap>
                  )}
                  <StyledTotalCost>
                    <span>Общая стоимость:</span>
                    {goods.some((el) => el.delivery) ? (
                      <StyledCost>
                        {(
                          goods.reduce((a, b) => a + b.totalCost, 0) +
                          deliveryPrice.cost -
                          totalDiscount -
                          giftDiscount.value
                        ).toLocaleString()}{" "}
                        &#8381;
                      </StyledCost>
                    ) : (
                      <span>
                        {(
                          goods.reduce((a, b) => a + b.totalCost, 0) -
                          totalDiscount -
                          giftDiscount.value
                        ).toLocaleString()}{" "}
                        &#8381;
                      </span>
                    )}
                  </StyledTotalCost>
                </StyledTotalWrap>
                <StyledSendBlock>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        error={errors.email?.message}
                        handleFocus={() => clearErrors("email")}
                        handleChange={(e) => setValue(e.target.value)}
                        name="email"
                        id="profile-email"
                        defaultValue={user?.email}
                        label="Введите ваш email"
                        {...field}
                      />
                    )}
                  />
                  <StyledButton type="submit">Оплатить</StyledButton>
                  <StyledParagraph>
                    Согласен с{" "}
                    <StyledLink as={as} to="/rules">
                      Условиями правил пользования
                    </StyledLink>{" "}
                    торговой площадкой и правилами возврата
                  </StyledParagraph>
                </StyledSendBlock>
              </StyledAside>
            </form>
            {errorPayment && errorPayment.message && isOpenPopup && (
              <Layer animation="fadeIn">
                <StyledPopupWrapper>
                  <div>{errorPayment.message}</div>
                  <StyledButton onClick={handleButtonClose}>Вернуться в корзину</StyledButton>
                </StyledPopupWrapper>
              </Layer>
            )}
          </>
        ) : null}
      </StyledWrapper>
    );
  }
);
