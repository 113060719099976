import { makeAutoObservable, observable } from "mobx";
import {
  returnNewArrayById,
  returnNewShopItem,
  updateLocalStorage,
  removeItemBasket,
  updateObjectInArray,
} from "../utils/utils";
import { backendLogger } from "../utils/logger";

export class BasketStore {
  goodsData = [];
  ticketFormats = {};

  setGoods = (goods) => {
    this.goodsData = returnNewShopItem(this.goodsData, goods);
  };

  addShop = (item) => {
    this.setGoods(item);
    updateLocalStorage(`basketGoods`, `add`, item);
  };

  updateGoods = (item) => {
    this.goodsData = updateObjectInArray(this.goodsData, item);
  };

  addGoods = (goods) => {
    this.goodsData = returnNewArrayById(this.goodsData, goods);
  };

  removeShop = (item) => {
    this.goodsData = removeItemBasket(this.goodsData, item);
    updateLocalStorage(`basketGoods`, `delete`, item);
  };

  setTicketFormats = (item) => {
    this.ticketFormats = item;
  };

  loadDefaultGoods = () => {
    if (localStorage.basketGoods) {
      const goods = JSON.parse(localStorage.basketGoods);

      goods.forEach((el) => {
        this.addGoods(el);
      });
    }
  };
  checkTicketsBeforePaymentByFormatId = async (format_id) => {
    const response = await this.api.get(`/api/event_format/${format_id}`);

    if (response.status === 200) {
      this.setTicketFormats(response.data);
    } else if (response.status >= 400) {
      backendLogger(response);
    }
  };

  get goods() {
    return this.goodsData;
  }

  get ticketFormatsInBasket() {
    return this.ticketFormats;
  }

  constructor(api) {
    this.api = api;
    this.loadDefaultGoods();

    makeAutoObservable(this, { api: observable.ref });
  }
}
