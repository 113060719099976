import { optionsSize } from "../mocks/calendarOptions";

export const returnAuthConfig = () => {
  const token = localStorage.getItem("token");

  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  };
};

export const isMobileTabletResponsive = (size) => {
  if (size.length) {
    return size === "tablet" || size === "small" || size === "xsmall";
  }
};

export const updateObject = (obj, payload) => {
  return { ...obj, ...payload };
};

export const returnNewShopItem = (arr, payload) => {
  const oldValue = arr.find((el) => el.public_id === payload.public_id && el.size === payload.size);

  if (oldValue) {
    const filterArr = arr.filter((el) => {
      return !(el.public_id === payload.public_id && el.size === payload.size);
    });

    return [...filterArr, { ...oldValue, ...payload }];
  } else return [...arr, payload];
};

export const returnNewArrayById = (arr, payload, selector = `format_public_id`) => {
  const newValue = arr.find((el) => el[selector] === payload[selector]);

  if (newValue === undefined) {
    // если элемента нет - добавляет
    return [...arr, payload];
  } else {
    // если элемент есть заменяет его с обновленными значениями
    const filterArr = arr.filter((el) => el[selector] !== payload[selector]);
    return [...filterArr, { ...newValue, ...payload }];
  }
};

export const updateObjectInArray = (array, action) => {
  return array.map((item) => {
    if (action.selector === `shop`) {
      if (item.public_id === action.public_id && item.size === action.size) {
        return { ...item, ...action };
      }
    } else {
      // return an updated value
      if (item.public_id === action.public_id) return { ...item, ...action };
    }
    return item;
  });
};

export const removeItemBasket = (arr, item) => {
  if (item.selector === `shop`) {
    return arr.filter((el) => !(el.public_id === item.public_id && el.size === item.size));
  } else {
    return arr.filter((el) => el.public_id !== item.public_id);
  }
};

export const updateLocalStorage = (store, action, payload) => {
  let oldData = [];
  let newData = [];

  if (localStorage.getItem(store)) {
    oldData = JSON.parse(localStorage.getItem(store));
  }

  if (action === `add`) {
    if (payload.selector === `shop`) {
      newData = returnNewShopItem(oldData, payload);
    } else newData = returnNewArrayById(oldData, payload); // add + update array
  } else if (action === `delete`) {
    newData = removeItemBasket(oldData, payload); // delete from array
  } else if (action === `update`) {
    newData = updateObject(oldData, payload); // update one object
  } else if (action === `deleteAll`) {
    newData = [];
  }

  localStorage.setItem(store, JSON.stringify(newData));
};

export const objectEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) !== JSON.stringify(obj2);
};
export const returnNewArrayByType = (arr, payload) => {
  let cb = () => {},
    filterCb = () => {};
  if (payload.selector === `shop`) {
    cb = (el, payload) => el.public_id === payload.public_id && el.size === payload.size;
    filterCb = (el, payload) => !(el.public_id === payload.public_id && el.size === payload.size);
  } else {
    cb = (el, payload) => el.public_id === payload.public_id;
    filterCb = (el) => el.public_id !== payload.public_id;
  }

  const oldValue = arr.find(cb);

  if (oldValue) {
    const filterArr = arr.filter(filterCb);
    return [...filterArr, { ...oldValue, ...payload }];
  } else return [...arr, payload];
};
export const saleCounter = (oneGoods, sale) => {
  let discount = 0;
  if (sale.code_count >= oneGoods.count) {
    discount = oneGoods.price * oneGoods.count * (sale.discount / 100);
  } else {
    discount = oneGoods.price * sale.code_count * (sale.discount / 100);
  }
  return discount < 0 ? 0 : discount;
};
export const returnElementById = (arr, item, selector = `public_id`) => {
  if (arr.length) {
    if (item.selector === "shop") {
      return arr.find((elem) => elem[selector] === item.public_id && elem.size === item.size);
    } else {
      return arr.find((elem) => elem[selector] === item.public_id);
    }
  } else {
    return null;
  }
};
export const capitalizeFirstLetter = (word) => {
  if (word && word !== undefined && word.length) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  } else return undefined;
};
export const searchTeam = (team_public_id, teams) => {
  const currentTeam = teams.find((t) => t.public_id === team_public_id);
  if (currentTeam) {
    const { number } = currentTeam;
    return { value: currentTeam["public_id"], label: number };
  } else return { value: null, label: "Команда не выбрана" };
};
export const searchSize = (size) => {
  return optionsSize.find((s) => s.value === size);
};
export const checkAndSetImgUrl = (evt) => {
  evt.target.src = imagePlaceholder;
};
