export const goals = [
  {
    id: 0,
    content: `Создание условий, обеспечивающих возможность эффективного развития
    триатлона, включая профессиональный, любительский, детско-юношеский
    спорт и массовые формы вида спорта в Москве, для выхода на передовые
    позиции на национальной и международной арене, утверждения принципов
    здорового образа жизни и содействия решению социальных проблем
    общества средствами триатлона как вида спорта.`,
  },
];

export const tasks = [
  {
    id: 0,
    content: `развитие спорта высших достижений, повышение эффективности подготовки триатлетов Москвы в составах сборных команд Москвы и Российской Федерации по триатлону к крупнейшим международным соревнованиям, в том числе к Олимпийским и Параолимпийским играм`,
  },
  {
    id: 1,
    content: `совершенствование системы подготовки спортивного резерва, вовлечение максимально возможного числа детей, подростков и молодежи в систематические занятия триатлоном`,
  },
  {
    id: 2,
    content: `содействие развитию любительского триатлона в Москве, повышение массовости занятий видом спорта`,
  },
  {
    id: 3,
    content: `противодействие использованию допинговых средств и распространению допинга в триатлоне`,
  },
  {
    id: 4,
    content: `обеспечение тесной интеграции московского триатлона на всероссийском уровне, повышение статуса московского триатлона во всероссийской федерации`,
  },
  {
    id: 5,
    content: `развитие системы подготовки и повышения квалификации
    управленческих, педагогических, научных и других кадров, необходимых для эффективного функционирования и развития триатлона в Москве`,
  },
  {
    id: 6,
    content: `укрепление материально-технической базы и развитие инфраструктуры вида спорта, создание регионального центра развития вида спорта, содействие строительству и реконструкции спортивных сооружений для организации систематических занятий, и проведения спортивных и
    физкультурно-оздоровительных мероприятий по виду спорта`,
  },
  {
    id: 7,
    content: `информационное обеспечение, популяризация и пропаганда триатлона, совершенствование и развитие форм взаимодействия со средствами массовой информации`,
  },
];
