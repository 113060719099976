// export const REGEX_MAIL = /\S+@\S+\.\S+$/;
export const REGEX_MAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i;
export const REGEX_UP_REG = /[A-ZА-Я]+/;
export const REGEX_NUM = /[0-9]+/;
export const REGEX_LETTER = /[A-ZА-Я]+/i;
export const regNum = new RegExp(`^[0-9]+$`);
export const REGEX_PHONE = /(^8|7|\+7)((\d{10})|(\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}))/;

export const BASKET_COUNT = {
  max_count: 500,
  min_count: 1,
};
export const REG_STATUS = {
  FALSE: false,
  TRUE: true,
};

export const AuthorizationStatus = {
  AUTH: `AUTH`,
  NO_AUTH: `NO_AUTH`,
};

export const screens = {
  first: "search", // первый поисковый экран
  result: "result", // результат поиска - карточки
  dataMember: "dataMember", // данные участника при поиске
  thanks: "thanks", // благодарность после регистрации
};
