export const VALIDATION_ERROR_MESSAGES = {
  isRequired: "Заполните поле!",
  numberMustBePositive: "Значение должно быть больше 0",
  numberMustBeInteger: "Значение должно быть целым числом",
  size: "Выберите размер!",
  fileSize: "Размер изображения слишком большой!",
  fileUpload: "Загрузите файл!",
  fileFormat: "Неподдерживаемый формат!",
  address: "Введите корректный адрес!",
  index: "Введите корректный Индекс!",
  indexCorrect: "Попробуйте другой индекс!",
  time: "Введите корректное время!",
};

export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const MAX_SIZE_IMAGE = 2097152;
//2097152 = 2mb

export const DEFAULT_INDEX_LENGTH = 6;
