import { makeAutoObservable, observable } from "mobx";

export class MembersStore {
  membersGroup = [];
  members = [];
  coaches = [];

  setMembersGroup = (groups) => {
    this.membersGroup = groups;
  };

  setMembers = (members) => {
    this.members = members;
  };

  setCoaches = (coaches) => {
    this.coaches = coaches;
  };

  loadMembersGroup = async () => {
    const response = await this.api.get("/api/members/group");

    if (response.status === 200) {
      this.setMembersGroup(response.data.values);
    }
  };

  loadMembers = async (publicId) => {
    const response = await this.api.get(`/api/members/group/${publicId}`);

    if (response.status === 200) {
      this.setMembers(response.data.values);
    }
  };

  loadAthletes = async () => {
    const response = await this.api.get(`/api/members/kind/athletes`);

    if (response.status === 200) {
      this.setMembers(response.data.values);
    }
  };

  loadCoaches = async () => {
    const response = await this.api.get(`/api/members/kind/coaches`);

    if (response.status === 200) {
      this.setCoaches(response.data.values);
    }
  };

  constructor(api, additionalApi) {
    this.api = api;
    this.additionalApi = additionalApi;

    makeAutoObservable(this, {
      api: observable.ref,
      additionalApi: observable.ref,
    });
  }
}
