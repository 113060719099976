const getNameId = (ticket) => {
  if (ticket.team_public_id) return `team_public_id`;
  return `format_public_id`;
};

export const useSubmit = ({
  gender,
  setGenderError,
  ticket,
  registrationTicket,
  fields,
  setError,
}) => {
  return {
    onSubmit: async (data) => {
      const { birth, firstName, lastName, phone, size, region, team } = data;

      let additional_fields = [];
      if (fields.length) {
        additional_fields = fields.map((el) => {
          const name = el.name;

          if (data[name]?.length === 0 && el.required) {
            setError(name, { type: "custom", message: "Заполните поле!" });
          }
          return {
            value: data[name],
            name,
            label: el.label,
            required: el.required,
          };
        });
      }

      const newDate = birth.split(".").reverse().join("-");
      const birth_date = new Date(newDate);
      const idName = getNameId(ticket);

      const sendObj = {
        birth_date,
        public_id: ticket.public_id,
        first_name: firstName,
        last_name: lastName,
        item_size: size && size,
        [idName]: ticket.event_format.public_id,
        phone: phone?.slice(1),
        gender,
        additional_fields,
      };

      if (!gender) {
        setGenderError("Выберите одно из значений!");
      }

      if (ticket.event_format.regions) {
        if (region) {
          const ticketObj = {
            ...sendObj,
            region,
          };

          registrationTicket(ticketObj);
        }
      } else if (ticket.info.type_team === "place") {
        if (team) {
          const ticketObj = {
            ...sendObj,
            team_public_id: team,
          };

          registrationTicket(ticketObj);
        }
      } else if (birth && firstName && lastName && gender) {
        registrationTicket(sendObj);
      }
    },
  };
};
