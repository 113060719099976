import React from "react";
import { routes } from "../../const/routes";
import { getFormatTime, times } from "../../utils/date";
import { ReactComponent as TimeLogo } from "../../images/svg/time.svg";
import { capitalizeFirstLetter } from "../../utils/utils";
import {
  StyledA,
  StyledAwait,
  StyledBtnHoverNoReg,
  StyledBtnHoverReg,
  StyledCover,
  StyledCoverImg,
  StyledInfo,
  StyledInfoLabel,
  StyledItem,
  StyledLogo,
  StyledPast,
  StyledWrap,
  StyledWrapper,
} from "./styles";

export const TicketEventsCard = ({ item, status, past, as, transfered }) => {
  const userObj = JSON.parse(localStorage.getItem("user"));

  return (
    <StyledItem>
      <StyledWrapper>
        <div>
          <StyledWrap>
            <StyledLogo src={item.event.event_type.logo} alt={item.event_format.title} />
          </StyledWrap>
          {transfered && (
            <StyledInfo>
              <span>{item.info.email}</span>
            </StyledInfo>
          )}
          <StyledInfo>
            <StyledInfoLabel>Формат:</StyledInfoLabel>
            {item.event_format.title}
          </StyledInfo>
          <StyledInfo>
            <StyledInfoLabel>Дата и время старта:</StyledInfoLabel>
            {getFormatTime(item)}
          </StyledInfo>
          <StyledInfo>
            <StyledInfoLabel>Город:</StyledInfoLabel>
            {item.city.name_ru}
          </StyledInfo>
          {status ? (
            <>
              {item.info.first_name && item.info.last_name && (
                <StyledInfo>
                  <StyledInfoLabel>ФИО:</StyledInfoLabel>
                  {`${capitalizeFirstLetter(item.info.first_name)} ${capitalizeFirstLetter(
                    item.info.last_name
                  )}`}
                </StyledInfo>
              )}
              {item.event_format.insurance_available && (
                <StyledInfo>
                  <StyledInfoLabel>Страховка:</StyledInfoLabel>
                  {item.info.insurance ? ` Есть` : `Нет`}
                </StyledInfo>
              )}
            </>
          ) : null}
        </div>

        {status ? (
          <>
            <StyledInfo>
              <StyledInfoLabel>Номер:</StyledInfoLabel>№&nbsp;
              {item.info.number || `*****`}
              {item.team && (
                <>
                  <StyledInfoLabel>Команда:</StyledInfoLabel>№&nbsp;
                  {item.team.number}
                  {`${item.info.inside_number ? "-" + item.info.inside_number : ""}`}
                </>
              )}
            </StyledInfo>
            <StyledA as={as} to={`${routes.myEvents.path}/${item.public_id}`}>
              <StyledBtnHoverNoReg>Билет зарегистрирован</StyledBtnHoverNoReg>
              <StyledBtnHoverReg>Подробнее</StyledBtnHoverReg>
            </StyledA>
          </>
        ) : !past ? (
          <>
            <StyledInfo>
              <StyledInfoLabel>Номер билета:</StyledInfoLabel>№{item.ticketNumber || `*****`}
            </StyledInfo>
            {item.status === `processing` ? (
              <StyledAwait>
                <TimeLogo />
                <span style={{ marginLeft: `0.75rem` }}>Ожидайте возврат</span>
              </StyledAwait>
            ) : (
              <StyledA as={as} to={`${routes.myEvents.path}/${item.public_id}`}>
                Зарегистрироваться
              </StyledA>
            )}
          </>
        ) : (
          <>
            <StyledInfo>
              <StyledInfoLabel>Номер билета:</StyledInfoLabel>№&nbsp;
              {item.info.number || `*****`}
            </StyledInfo>
            {/*   {item.event_format.online && item.event_format.online ? (
              <StyledPast as={as} to={`${routes.myEvents.path}/${item.public_id}`}>
                Завершено
              </StyledPast>
            ) : ( */}
            <StyledPast>Завершено</StyledPast>
            {/*   )} */}
          </>
        )}
      </StyledWrapper>
      <StyledCover>
        <StyledCoverImg
          src={item.event.banners.landing_page.mobile_picture}
          alt={item.event.event_type.title}
        />
      </StyledCover>
    </StyledItem>
  );
};
