import banner from "../../images/home/Default.jpg";
import { Breadcrumbs, Container, Banner, DocumentList } from "storybook";
import { resultsLinks } from "../../mocks/resultsLinks";
import { useLocation } from "react-router-dom";

const Results = () => {
  const location = useLocation();

  return (
    <>
      <Banner cover={banner} />
      <Container>
        <Breadcrumbs location={location} />
        <DocumentList result={resultsLinks} />
      </Container>
    </>
  );
};

export default Results;
