import Image from "../../images/banners/federation-banner.jpg";
import { Breadcrumbs, Banner, Container, ContactsItem } from "storybook";
import { useLocation } from "react-router-dom";

const Contacts = () => {
  const location = useLocation();

  return (
    <>
      <Banner cover={Image} />
      <Container>
        <Breadcrumbs location={location} />
        <ContactsItem />
      </Container>
    </>
  );
};

export default Contacts;
