import { makeAutoObservable, observable } from "mobx";
import { backendLogger } from "../utils/logger";

export class AntiDopingStore {
  data = [];

  setAntiDopingDocs = (data) => {
    this.data = data;
  };

  loadAntiDopingDocs = async () => {
    const response = await this.api.get(this.additionalApi + "/api/document/type/antidoping");

    if (response.status === 200) {
      this.setAntiDopingDocs(response.data);
    } else if (response.status >= 400) {
      backendLogger(response);
    }
  };

  get antiDopingDocs() {
    return this.data;
  }

  constructor(api, additionalApi) {
    this.api = api;
    this.additionalApi = additionalApi;
    this.loadAntiDopingDocs();

    makeAutoObservable(this, { api: observable.ref, additionalApi: observable.ref });
  }
}
