import { createContext, useContext } from "react";

export const iocContext = createContext(null);

export function useInject(name) {
  const container = useContext(iocContext);

  if (!container) {
    throw new Error("You have forgotten to wrap app to iocContext.Provider");
  }

  return container.get(name);
}
