import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs, Container } from "storybook";
import { routes } from "../../const/routes";
import {
  StyledLinkSettings,
  StyledLinkTickets,
  StyledList,
  StyledNotExistBlock,
  StyledNotExistLink,
  StyledNotExistTitle,
  StyledRegEvents,
  StyledTitle,
  StyledTitleLink,
  StyledTransferredEvents,
  StyledUnRegEvents,
  StyledWrap,
  StyledWrapButtons,
} from "./styles";
import { REG_STATUS } from "../../const/const";
import { checkRegDate } from "../../utils/date";
import { useTicketsStore } from "../../hooks/useTicketsStore";
import { observer } from "mobx-react";
import { TicketEventsCard } from "../../components/TicketEventsCard";

const getFilteredTickets = (tickets, status) => {
  const userObj = JSON.parse(localStorage.getItem("user"));

  return tickets.filter(
    (ticket) =>
      ticket.info.registered === status &&
      !checkRegDate(ticket.event_format.start_time) &&
      ticket.original_user_public_id !== userObj?.public_id
  );
};

const getPastTickets = (tickets) => {
  return tickets.filter((el) => checkRegDate(el.event_format.start_time));
};

export const MyEvents = observer(() => {
  const location = useLocation();
  const [isPastTicketTitleClick, setIsPastTicketTitleClick] = useState(false);
  const [isTransferTitleClick, setIsTransferTitleClick] = useState(false);

  const { tickets, transferTicket, getTransferTicket, loadTickets } = useTicketsStore();

  const handleTransferClick = () => {
    setIsTransferTitleClick(!isTransferTitleClick);
    getTransferTicket();
  };

  useEffect(() => {
    loadTickets();
  }, []);

  return (
    <Container>
      <Breadcrumbs location={location} />
      <StyledWrap>
        <StyledWrapButtons>
          <StyledLinkTickets as={Link} to={routes.myEvents.path}>
            Билеты
          </StyledLinkTickets>

          <StyledLinkSettings as={Link} to={routes.me.path}>
            Настройки
          </StyledLinkSettings>
        </StyledWrapButtons>
      </StyledWrap>

      {getFilteredTickets(tickets, REG_STATUS.FALSE).length === 0 &&
      getFilteredTickets(tickets, REG_STATUS.TRUE).length === 0 &&
      getPastTickets(tickets).length === 0 ? (
        <StyledNotExistBlock>
          <StyledNotExistTitle>Вы пока не участвуете ни в одном мероприятии</StyledNotExistTitle>
          <StyledNotExistLink as={Link} to={routes.events.path}>
            Перейти в календарь мероприятий
          </StyledNotExistLink>
        </StyledNotExistBlock>
      ) : (
        <>
          {getFilteredTickets(tickets, REG_STATUS.FALSE).length ? (
            <StyledUnRegEvents>
              <StyledTitle>Незарегистрированные мероприятия</StyledTitle>
              <StyledList>
                {getFilteredTickets(tickets, REG_STATUS.FALSE).map((item) => (
                  <TicketEventsCard
                    as={Link}
                    item={item}
                    key={item.public_id}
                    status={REG_STATUS.FALSE}
                  />
                ))}
              </StyledList>
            </StyledUnRegEvents>
          ) : null}

          {getFilteredTickets(tickets, REG_STATUS.TRUE).length ? (
            <StyledRegEvents>
              <StyledTitle>Зарегистрированные мероприятия</StyledTitle>
              <StyledList>
                {getFilteredTickets(tickets, REG_STATUS.TRUE).map((item) => (
                  <TicketEventsCard
                    as={Link}
                    item={item}
                    key={item.public_id}
                    status={REG_STATUS.TRUE}
                  />
                ))}
              </StyledList>
            </StyledRegEvents>
          ) : null}

          {getPastTickets(tickets).length ? (
            <StyledUnRegEvents>
              <StyledTitleLink
                click={isPastTicketTitleClick}
                onClick={() => setIsPastTicketTitleClick(!isPastTicketTitleClick)}
              >
                Прошедшие мероприятия
              </StyledTitleLink>
              <StyledList>
                {isPastTicketTitleClick &&
                  getPastTickets(tickets).map((item) => (
                    <TicketEventsCard as={Link} item={item} key={item.public_id} past={true} />
                  ))}
              </StyledList>
            </StyledUnRegEvents>
          ) : null}
        </>
      )}
      <StyledTransferredEvents>
        <StyledTitleLink click={isTransferTitleClick} onClick={handleTransferClick}>
          Переданные билеты
        </StyledTitleLink>
        {isTransferTitleClick && (
          <StyledList>
            {transferTicket.length ? (
              transferTicket.map((item) => (
                <TicketEventsCard as={Link} item={item} key={item.public_id} transfered />
              ))
            ) : (
              <h3>Переданные билеты не найдены!</h3>
            )}
          </StyledList>
        )}
      </StyledTransferredEvents>
    </Container>
  );
});
